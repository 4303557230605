import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import { Link as RouterLink, useHistory } from "react-router-dom";

import Header from "../components/Header";
import Basket from "../components/Basket";
import { useGlobalStyles } from "../styles/global";

import { useGetPublicSettingQuery, useSendBookingRequestMutation } from "../api/generated";
import { IAppStore } from "../store/appStore.interface";
import { Alert } from '@material-ui/lab';
import ReactMarkdown from 'react-markdown';
import InfoIcon from '@material-ui/icons/Info';

type SummaryProps = {
  store: IAppStore;
};

export default function Summary({ store }: SummaryProps) {
  const history = useHistory();

  const classes = {
    ...useGlobalStyles(),
    ...useStyles()
  };

  const [showCollectionHintDetails, setShowCollectionHintDetails] = useState(
    false
  );
  const [showShowGeneralTermsPopup, setShowGeneralTermsPopup] = useState(false);
  const [showSizeTermsPopup, setShowSizeTermsPopup] = useState(false);

  const [
    sendBookingRequestMutation
  ] = useSendBookingRequestMutation();

  const {
    data: infoSizeTermsResponse,
  } = useGetPublicSettingQuery({
    variables: { key: "markdown-content-info-size-terms" }
  });
  const infoSizeTermsMarkdown =
    infoSizeTermsResponse?.publicSettings_by_pk?.value ?? "";

  const {
    data: infoCollectionHintResponse,
  } = useGetPublicSettingQuery({
    variables: { key: "markdown-content-info-collection-hint" }
  });
  const infoCollectionHintMarkdown =
    infoCollectionHintResponse?.publicSettings_by_pk?.value ?? "";

  const {
    data: infoGeneralTermsResponse,
  } = useGetPublicSettingQuery({
    variables: { key: "markdown-content-info-general-terms" }
  });
  const infoGeneralTermsMarkdown =
    infoGeneralTermsResponse?.publicSettings_by_pk?.value ?? "";  

  // Handlers
  const handleReservationRequest = async () => {
    const request = store.getReservation();

    try {
      const bookingRequestResult = await sendBookingRequestMutation({
        variables: {
          objects: [
            {
              request: JSON.stringify(request)
            }
          ]
        }
      });

      const bookingRequestId =
        bookingRequestResult?.data?.insert_bookingRequest?.returning[0]?.id ??
        "";

      if (bookingRequestId) {
        store.setBookingRequestId(bookingRequestId);
      }

      history.push("/confirmation");
    } catch (e) {
      console.log(`Error sending reservation: ${JSON.stringify(e)}`);
    }
  };

  const handleShowCollectionHintDetails = (show: boolean): void => {
    setShowCollectionHintDetails(show);
  };

  const handleShowGeneralTermsPopup = (show: boolean): void => {
    setShowGeneralTermsPopup(show);
  };

  const handleShowSizeTermsPopup = (show: boolean): void => {
    setShowSizeTermsPopup(show);
  };

  if (showShowGeneralTermsPopup) {
    return (
      <>
        <Container component="main" maxWidth="md">
          <Box
            className={`${classes.paper} ${classes.paperInfo}`}
          >
            <ReactMarkdown>
              {infoGeneralTermsMarkdown}
            </ReactMarkdown>
          </Box>
        </Container>

        <AppBar
          position={'relative'}
          color="default"
          className={classes.appBar}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleShowGeneralTermsPopup(false)}
          >
            Zurück
          </Button>
        </AppBar>
      </>
    );
  }

  if (showSizeTermsPopup) {
    return (
      <>
        <Container component="main" maxWidth="md">
          <Box
            className={`${classes.paper} ${classes.paperInfo}`}
          >
            <ReactMarkdown>
              {infoSizeTermsMarkdown}
            </ReactMarkdown>
          </Box>
        </Container>

        <AppBar
          position={'relative'}
          color="default"
          className={classes.appBar}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleShowSizeTermsPopup(false)}
          >
            Zurück
          </Button>
        </AppBar>
      </>
    );
  }

  if (showCollectionHintDetails) {
    return (
      <>
        <Container component="main" maxWidth="md">
          <Box
            className={`${classes.paper} ${classes.paperInfo}`}
          >
            <ReactMarkdown>
              {infoCollectionHintMarkdown}
            </ReactMarkdown>
          </Box>
        </Container>

        <AppBar
          position={'relative'}
          color="default"
          className={classes.appBar}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleShowCollectionHintDetails(false)}
          >
            Zurück
          </Button>
        </AppBar>
      </>
    );
  }

  return (
    <>
      <Header store={store} />
      <Container component="main" maxWidth="md" className={classes.root}>
        <Box className={`${classes.content} ${classes.paperOverride}`}>
          <Typography variant="h1">Übersicht</Typography>

          <Alert severity={'warning'} className={classes.collectionHint} icon={false}>
            <Grid container>
              <Grid item xs={11}>
                <strong>Achtung</strong>: Reservierte Artikel müssen bis spätestens 10:30 abgeholt werden!
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => handleShowCollectionHintDetails(true)}><InfoIcon /></IconButton>
              </Grid>
            </Grid>

            <Divider className={classes.divider}/>

            {/* @todo: tenant specific */}
            <Grid container>
              <Grid item xs={11}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={store.getSizeTermsAccepted()}
                      onChange={event =>
                        store.setSizeTermsAccepted(event.currentTarget.checked)
                      }
                      name="termsAccepted"
                      color="primary"
                    />
                  }
                  label={'Alle Größenangaben sind korrekt'}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => handleShowSizeTermsPopup(true)}><InfoIcon /></IconButton>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={11}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={store.getGeneralTermsAccepted()}
                      onChange={event =>
                        store.setGeneralTermsAccepted(event.currentTarget.checked)
                      }
                      name="termsAccepted"
                      color="primary"
                    />
                  }
                  label={'Ich akzeptiere die AGBs.'}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => handleShowGeneralTermsPopup(true)}><InfoIcon /></IconButton>
              </Grid>
            </Grid>

          </Alert>

          <Basket store={store} />

          <Divider className={classes.dividerTotal} />

          <Grid container>
            <Grid item xs={8}>
              <Typography variant="h5">
                Persönliche Daten
              </Typography>

              {store.getBookingRequest().type === "customer" && (
                <Grid container>
                  <Grid item xs={12}>
                    {store.getCustomer().firstname} {store.getCustomer().name} /{" "}
                    {store.getCustomer().street} {store.getCustomer().streetnumber}
                    <br />
                    {store.getCustomer().countryCode} {store.getCustomer().zipCode}{" "}
                    {store.getCustomer().city}
                    <br />
                    {store.getCustomer().email}
                    <br />
                    {store.getCustomer().phone}
                    {/*<br />*/}
                    {/*{dateFns.format(*/}
                    {/*  parseISO(store.getCustomer().birthdate ?? ""),*/}
                    {/*  "dd.MM.yyyy"*/}
                    {/*)}*/}
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="text"
                color="default"
                startIcon={<EditIcon />}
                component={RouterLink}
                to="/address"
                className={classes.addressEditButton}
                style={{marginTop: '1.5em'}}
              >Bearbeiten</Button>
            </Grid>
          </Grid>

          {store.getBookingRequest().type === "admin" && (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant={"subtitle1"} color={"error"}>
                  Daten werden übernommen.
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>

      <AppBar
        position={'relative'}
        color="default"
        className={classes.appBar}
        style={{backgroundColor: '#000'}}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={
            store.getBasket().items.length === 0 ||
            !store.getSizeTermsAccepted() ||
            !store.getGeneralTermsAccepted()
          }
          fullWidth
          onClick={handleReservationRequest}
        >
          Verbindlich reservieren
        </Button>

        <Button
          variant="outlined"
          color="primary"
          fullWidth
          startIcon={<AddCircleIcon />}
          component={RouterLink}
          to="/products"
        >
          Weiter reservieren
        </Button>
      </AppBar>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      top: theme.spacing(0),
      //height: `calc(100% - ${theme.spacing(4)}px)`,
      backgroundImage: 'url(/theme/img/bg/BP-WB_Slopestyle_small_sRGB.png)',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      padding: 0
    },
    content: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(0),
      position: 'relative',
      height: '100%',
      textAlign: 'left',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.8)'
    },
    paperOverride: {
      paddingBottom: "0",
      marginBottom: "2em"
    },
    divider: {
      marginTop: "0.5em",
      marginBottom: "0.5em"
    },
    addressEditButton: {
      padding: 0,
      fontSize: "0.9em",
      paddingLeft: 10,
      paddingRight: 10
    },
    dividerTotal: {
      height: "5px"
    },
    collectionHint: {
      marginBottom: "2em",
      '& .MuiFormControlLabel-label': {
        fontSize: '1em',
      }
    },
    paperInfo: {
      paddingTop: "2em"
    },
    infoLink: {
      color: theme.palette.error.main,
      display: 'inline',
      fontSize: '0.875em'
    },
    infoSubheader: {
      textAlign: "center",
      fontWeight: "normal"
    }
  })
);
