import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  useGetProductTypeQuery,
  useGetPublicSettingQuery
} from "../api/generated";
import { IAppStore } from "../store/appStore.interface";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  Switch,
  TextField
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import ReactMarkdown from "react-markdown";

import Header from "../components/Header";
import { useGlobalStyles } from "../styles/global";
import { formatPrice, priceTotalForDuration } from "../helpers/price";
import { Alert } from '@material-ui/lab';

type ProductTypeProps = {
  store: IAppStore;
};

export default function ProductType({ store }: ProductTypeProps): React.ReactNode {
  const classes = {
    ...useGlobalStyles(),
    ...useStyles()
  };

  const history = useHistory();

  const { productTypeId } = useParams<{ productTypeId: string }>();

  const weightFieldRef = React.useRef<HTMLInputElement>(null);
  const nameFieldRef = React.useRef<HTMLInputElement>(null);

  const [showProtectionDetails, setShowProtectionDetails] = useState(false);
  const [showInsuranceDetails, setShowInsuranceDetails] = useState(false);

  const [showSizeTermsPopup, setShowSizeTermsPopup] = useState(false);

  const { data, loading, error } = useGetProductTypeQuery({
    variables: {
      startDate: store.getStartDate(),
      endDate: store.getEndDate(),
      duration: store.getDuration(),
      productTypeId: productTypeId,
      locationId: store.getLocation()?.id
    },
    skip: !store.getLocation()
  });

  const productType = data?.productTypes_by_pk;
  const modelTypeId = productType?.productTypeModelId;
  const products = productType?.products ?? [];

  const {
    data: infoProtectionResponse,
    loading: loadingInfoProtection
  } = useGetPublicSettingQuery({
    variables: { key: "markdown-content-info-protection" }
  });
  const infoProtectionMarkdown =
    infoProtectionResponse?.publicSettings_by_pk?.value ?? "";

  const {
    data: infoInsuranceResponse,
    loading: loadingInfoInsurance
  } = useGetPublicSettingQuery({
    variables: { key: "markdown-content-info-insurance" }
  });
  const infoInsuranceMarkdown =
    infoInsuranceResponse?.publicSettings_by_pk?.value ?? "";

  const {
    data: infoSizeTermsResponse,
    loading: loadingInfoSizeTerms
  } = useGetPublicSettingQuery({
    variables: { key: "markdown-content-info-size-terms" }
  });
  const infoSizeTermsMarkdown =
    infoSizeTermsResponse?.publicSettings_by_pk?.value ?? "";

  if (loading || loadingInfoProtection || loadingInfoInsurance || loadingInfoSizeTerms) {
    return <p>Loading...</p>;
  }

  if (error) return <p>Error :(</p>;

  const onSelectProduct = (product: any, productType: any) => {
    const currentProduct = store.getCurrentProduct();

    if (!product) return;

    const priceAggregate = product.productPrices_aggregate?.aggregate?.max;

    if (!priceAggregate) return;

    store.setCurrentProduct({
      ...currentProduct,
      id: product.id,
      data: {
        size: product.size,
        price: {
          basePricePerDay: priceAggregate.basePricePerDay,
          insuranceCostsPerDay: product.productPrices_aggregate.aggregate.max.insuranceCostsPerDay,
          protectionCostsPerDay: product.productPrices_aggregate.aggregate.max.protectionCostsPerDay
        },
        name: productType.name
      }
    });
  };

  const onResetProduct = () => {
    const product = store.getCurrentProduct();

    store.setCurrentProduct({
      ...product,
      id: null,
      data: undefined
    });
  };

  const onFocusDriverWeight = (): void => {
    if (weightFieldRef && weightFieldRef.current) {
      setTimeout(() => {
        weightFieldRef?.current?.scrollIntoView();
      }, 300);
    }
  };

  const onFocusDriverName = (): void => {
    if (nameFieldRef && nameFieldRef.current) {
      setTimeout(() => {
        nameFieldRef?.current?.scrollIntoView();
      }, 300);
    }
  };

  const onChangeDriverWeight = (weight: number) => {
    const product = store.getCurrentProduct();

    store.setCurrentProduct({
      ...product,
      driver: {
        ...product.driver,
        weight: weight
      }
    });
  };

  const onChangeDriverName = (name: string) => {
    const product = store.getCurrentProduct();

    store.setCurrentProduct({
      ...product,
      driver: {
        ...product.driver,
        name: name
      }
    });
  };

  const onSetDriver = () => {
    const product = store.getCurrentProduct();

    store.setCurrentProduct({
      ...product,
      driver: {
        name: product.driver?.name ?? "",
        weight: product.driver?.weight ?? 0,
        configured: true
      }
    });
  };

  const onResetDriver = () => {
    const product = store.getCurrentProduct();

    store.setCurrentProduct({
      ...product,
      driver: {
        name: product.driver?.name ?? "",
        weight: product.driver?.weight ?? 0,
        configured: false
      }
    });
  };

  const onChangeInsurance = (selected: boolean) => {
    const product = store.getCurrentProduct();

    store.setCurrentProduct({
      ...product,
      crosssells: {
        ...product.crosssells,
        insurance: selected
      }
    });
  };

  const onChangeProtection = (selected: boolean) => {
    const product = store.getCurrentProduct();

    store.setCurrentProduct({
      ...product,
      crosssells: {
        ...product.crosssells,
        protection: selected
      }
    });
  };

  const handleProtectionInfo = (show: boolean): void => {
    setShowProtectionDetails(show);
  };

  const handleInsuranceInfo = (show: boolean): void => {
    setShowInsuranceDetails(show);
  };

  const handleAddToBasket = () => {
    // Add product to basket
    store.addProductToBasket(store.getCurrentProduct());
    // Reset current product
    store.setCurrentProduct({
      id: null
    });

    // to={store.isCustomerValid() ? "/address/true" : "/address"}
    history.replace(store.isCustomerValid() ? "/summary" : "/address");
  };

  const handleShowSizeTermsPopup = (show: boolean): void => {
    setShowSizeTermsPopup(show);
  };

  if (showSizeTermsPopup) {
    return (
      <>
        <Container component="main" maxWidth="md">
          <Box
            className={`${classes.paper} ${classes.paperInfo} ${classes.scrollableContainer}`}
          >
            <ReactMarkdown
              className={classes.markdown}
            >
              {infoSizeTermsMarkdown}
            </ReactMarkdown>
          </Box>
        </Container>

        <AppBar
          position={store.getFooterPosition()}
          color="default"
          className={classes.appBar}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleShowSizeTermsPopup(false)}
          >
            Zurück
          </Button>
        </AppBar>
      </>
    );
  }

  if (showProtectionDetails) {
    return (
      <>
        <Container component="main" maxWidth="md">
          <Box
            className={`${classes.paper} ${classes.paperInfo} ${classes.scrollableContainer}`}
          >
            <ReactMarkdown
              className={classes.markdown}
            >
              {infoProtectionMarkdown}
            </ReactMarkdown>
          </Box>
        </Container>

        <AppBar
          position={store.getFooterPosition()}
          color="default"
          className={classes.appBar}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleProtectionInfo(false)}
          >
            Zurück
          </Button>
        </AppBar>
      </>
    );
  }

  if (showInsuranceDetails) {
    return (
      <>
        <Container component="main" maxWidth="md">
          <Box className={`${classes.paper} ${classes.paperInfo}`}>
            <ReactMarkdown>{infoInsuranceMarkdown}</ReactMarkdown>
          </Box>
        </Container>

        <AppBar
          position={store.getFooterPosition()}
          color="default"
          className={classes.appBar}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleInsuranceInfo(false)}
          >
            Zurück
          </Button>
        </AppBar>
      </>
    );
  }

  return productType ? (
    <>
      <Header store={store} />
      <Container component="main" maxWidth="md" className={classes.root}>
        <Box className={classes.content}>
          <>
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <figure style={{ padding: 0, margin: 0 }}>
                  <img
                    className={classes.productTypeImage}
                    src={productType?.image}
                    alt={productType?.name ?? "No description available"}
                  />

                  {/* @todo: tenant specific */}
                  <figcaption style={{ textAlign: 'center' }}>Symbolbild</figcaption>
                </figure>

              </Grid>
              <Grid item xs={7}>
                <Typography variant={"h2"} className={classes.productTitle}>
                  {productType?.category}
                </Typography>
                <Typography
                  variant={"body2"}
                  className={classes.productSubTitle}
                >
                  {productType?.name}
                </Typography>
                <Typography variant={"body2"}>
                  {productType?.description}
                </Typography>
              </Grid>
            </Grid>
          </>

          <Divider className={classes.divider} />
          {/**
           * END ProductPresentation Component
           */}
          {/** ================================================================================================ */}

          {/**
           * ProductSize Component
           * Props:
           *  - OnSelectSize
           *  - products (productType?.products?)
           */}
          {store.getCurrentProduct()?.data?.size ? (
            <>
              <Grid container className={classes.selectedFeature}>
                <Grid item xs={2} className={classes.editColumn}>
                  <IconButton
                    aria-label="edit"
                    color="default"
                    onClick={() => onResetProduct()}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant={"h5"}>Variante</Typography>

                  <Typography variant={"subtitle2"}>
                    Größe: {store.getCurrentProduct().data?.size}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <>
                <Typography variant={"h5"}>
                  Welche Größe benötigst du?
                </Typography>
                <Alert severity="warning" className={classes.sizesHint} onClick={() => handleShowSizeTermsPopup(true)}>
                  Achtung: Falsche Angabe = keine Bikeausgabe!
                  <Box className={classes.infoLink}>
                    <Button
                      startIcon={<InfoIcon style={{ width: '1em', height: '1em', marginBottom: '0.2em' }} />}
                      size={'small'}
                      color={'secondary'}
                      variant={'outlined'}
                    >
                      Mehr Infos
                    </Button>
                  </Box>
                </Alert>
                {
                  products.map(product => {
                    // subtract amount of products in basket from server side availabilities.
                    const amountInBasket = store
                      .getBasket()
                      .items.filter(i => i.product.id === product.id).length;
                    const availableBikes =
                      product?.snapshotAvailabilities_aggregate?.aggregate?.min
                        ?.availableBikes ?? 0;
                    const availabilities = availableBikes - amountInBasket;
                    const isActive = 0 < availabilities;
                    const price = priceTotalForDuration(
                      product?.productPrices_aggregate?.aggregate?.max
                        ?.basePricePerDay ?? 0,
                      store.getDuration()
                    );

                    return (
                      <Grid container key={product.id} className={classes.body}>
                        <Grid item xs={5}>
                          <Button
                            disabled={!isActive}
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              onSelectProduct(product, productType)
                            }
                            fullWidth={true}
                          >
                            {product.size}
                          </Button>
                        </Grid>
                        <Grid item xs={4} className={classes.sizeColumn}>
                          {(product.description && product.description.length > 0)
                            ? (<><strong>{product.description}</strong><br /></>)
                            : ''
                          }
                          Verfügbar: {availabilities}
                        </Grid>
                        <Grid item xs={3} className={classes.sizeColumn}>
                          {formatPrice(price)}
                        </Grid>
                      </Grid>
                    );
                  })}
              </>
            </>
          )}
          {/**
           * END ProductSize Component
           */}
          {/** ================================================================================================ */}

          {/**
           * ProductDriver Component
           * Props:
           *  - OnSelectWeight
           *  - OnSelectDriverName
           */}
          {store.getCurrentProduct()?.id ? (
            <>
              {store.getCurrentProduct()?.driver?.configured ? (
                <>
                  <Grid container className={classes.selectedFeature}>
                    <Grid item xs={2} className={classes.editColumn}>
                      <IconButton
                        aria-label="edit"
                        color="default"
                        onClick={() => onResetDriver()}
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant={"h5"}>Fahrer</Typography>
                      {modelTypeId === "configurable" && (
                        <Typography variant={"subtitle2"}>
                          Gewicht: {store.getCurrentProduct().driver?.weight} kg
                        </Typography>
                      )}
                      <Typography variant={"subtitle2"}>
                        Name:{" "}
                        {store.getCurrentProduct().driver?.name
                          ? store.getCurrentProduct().driver?.name
                          : "Keine Angabe"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                </>
              ) : (
                <>
                  <Typography variant={"h5"}>Angaben zum Fahrer</Typography>

                  {modelTypeId === "configurable" && (
                    <TextField
                      ref={weightFieldRef}
                      id="outlined-basic"
                      label="Gewicht"
                      variant="outlined"
                      fullWidth={true}
                      type="number"
                      value={store.getCurrentProduct().driver?.weight}
                      onChange={e =>
                        onChangeDriverWeight(parseInt(e.target.value))
                      }
                      onFocus={onFocusDriverWeight}
                    />
                  )}
                  <TextField
                    id="outlined-basic"
                    ref={nameFieldRef}
                    label="Name (optional)"
                    variant="outlined"
                    fullWidth={true}
                    value={store.getCurrentProduct().driver?.name ?? ""}
                    onChange={e => onChangeDriverName(e.target.value)}
                    onFocus={onFocusDriverName}
                  />
                </>
              )}
            </>
          ) : (
            <></>
          )}
          {/**
           * END ProductDriver Component
           */}
          {/** ================================================================================================ */}

          {/**
           * ProductCrossSells Component
           * Props:
           *  - OnSelectInsurance
           *  - OnSelectProtection
           */}
          {store.getCurrentProduct()?.id &&
          store.getCurrentProduct()?.driver?.configured &&
          modelTypeId === "configurable" /*products?.length > 1*/ ? (
            <>
              <Grid container>
                <Grid item xs={10}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          store.getCurrentProduct().crosssells?.protection ??
                          false
                        }
                        onChange={e => onChangeProtection(e.target.checked)}
                        name="protection"
                        color="default"
                      />
                    }
                    label={
                      <Typography variant={"subtitle2"}>
                        Schutzausrüstung
                        <br />
                        (zzgl.{" "}
                        {formatPrice(
                          priceTotalForDuration(
                            store?.getCurrentProduct()?.data?.price
                              ?.protectionCostsPerDay ?? 0,
                            store.getDuration()
                          )
                        )}
                        )
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    aria-label="Info"
                    color="default"
                    onClick={() => handleProtectionInfo(true)}
                  >
                    <InfoIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />

              <Grid container>
                <Grid item xs={10}>
                  <FormControlLabel
                    control={
                      <>
                        {!productType.forceInsurance ? (
                          <Switch
                            checked={
                              store.getCurrentProduct().crosssells?.insurance ??
                              false
                            }
                            onChange={e => onChangeInsurance(e.target.checked)}
                            name="insurance"
                            color="primary"
                          />
                        ) : (
                          <Box style={{marginLeft: '1em'}}>
                            <Hidden xsUp>
                              <Switch
                                checked={true}
                                disabled={true}
                                name="insurance"
                                color="primary"
                              />
                            </Hidden>
                          </Box>
                        )}
                      </>
                    }
                    label={
                      <Typography variant={"subtitle2"}>
                        Materialversicherung:&nbsp;<br />
                        {!productType.forceInsurance ? (
                          <>
                            zzgl.{" "}
                            {formatPrice(
                              priceTotalForDuration(
                                store?.getCurrentProduct()?.data?.price
                                  ?.insuranceCostsPerDay ?? 0,
                                store.getDuration()
                              )
                            )}
                          </>
                        ) : (
                          <>
                            Im Preis inkludiert.
                          </>
                        )}
                      </Typography>
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <IconButton
                    aria-label="Info"
                    color="default"
                    onClick={() => handleInsuranceInfo(true)}
                  >
                    <InfoIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Divider className={classes.divider} />
            </>
          ) : (
            <></>
          )}
          {/**
           * END ProductCrossSells Component
           */}
          {/** ================================================================================================ */}
        </Box>
      </Container>

      {store.getCurrentProduct()?.id &&
        !store.getCurrentProduct()?.driver?.configured && (
          <AppBar
            position={'relative'}
            color="default"
            className={classes.appBar}
          >
            {!store.getCurrentProduct().driver?.weight && (
              <>
                {modelTypeId === "configurable" && (
                  <Alert severity={'warning'}>
                    <Typography variant={"body2"} className={classes.primaryHint}>
                      Wir benötigen noch dein Gewicht, um das Bike auf dich
                      abstimmen zu können!
                    </Typography>
                  </Alert>
                )}
              </>
            )}
            <Button
              variant="contained"
              color="primary"
              disabled={!store.getCurrentProduct().driver?.weight}
              onClick={() => onSetDriver()}
              className={classes.primaryButton}
            >
              Weiter
            </Button>
          </AppBar>
        )}

      {store.getCurrentProduct()?.id &&
        ((store.getCurrentProduct()?.driver?.configured &&
            modelTypeId === "configurable") ||
          modelTypeId === "simple") && (
          <AppBar
            position={'relative'}
            color="default"
            className={classes.appBar}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={
                !(modelTypeId === "simple" || store.isCurrentProductValid())
              }
              fullWidth
              onClick={handleAddToBasket}
              className={classes.primaryButton}
              //component={RouterLink}
              //to={store.isCustomerValid() ? "/address/true" : "/address"}
            >
              Weiter
            </Button>
          </AppBar>
        )}
    </>
  ) : (
    <>Product not found</>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      top: theme.spacing(0),
      //height: `calc(100% - ${theme.spacing(4)}px)`,
      //backgroundImage: 'url(/theme/img/bg/BP-WP-ShredAcad_small_sRGB.png)',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      padding: 0
    },
    content: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      position: 'relative',
      height: '100%',
      textAlign: 'left',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.8)'
    },
    productTypeImage: {
      width: "100%",
      height: "auto"
    },
    sizeColumn: {
      textAlign: "left",
      paddingLeft: "1em",
      paddingTop: "0em",
      verticalAlign: "middle",
    },
    actionColumn: {
      textAlign: "center"
    },
    header: {
      fontWeight: 600,
      marginBottom: theme.spacing(2)
    },
    body: {
      marginBottom: theme.spacing(1)
    },
    productTitle: {
      textAlign: "left",
      padding: 0,
      margin: 0
    },
    productSubTitle: {
      marginBottom: "8px"
    },
    editColumn: {
      paddingTop: 0
    },
    selectedFeature: {
      "& .MuiTypography-h5": {
        marginBottom: 0,
        marginTop: "0.25em"
      }
    },
    paperInfo: {
      paddingTop: "2em"
    },
    infoSubheader: {
      textAlign: "center"
    },
    primaryHint: {
      padding: "8px",
      fontWeight: 500,
      textAlign: "center"
    },
    scrollableContainer: {
      // add enough space to scroll form into view on mobile devices
      minHeight: "180vh",
      height: "180vh"
    },
    markdown: {
      "& h2": {
        fontSize: "1.5rem",
        textAlign: "center",
        fontFamily: "Raleway, sans-serif",
        fontWeight: "300",
        lineHeight: "1.167",
        marginBottom: "0.75em",
        letterSpacing: "-0.01562em",
        paddingBottom: "0"
      },
      "& h5": {
        fontSize: "1rem",
        marginTop: "1.5em",
        fontFamily: "Raleway, sans-serif",
        fontWeight: "400",
        lineHeight: "1.334",
        marginBottom: "1em",
        letterSpacing: "0em",
        textAlign: "center"
      }
    },
    sizesHint: {
      marginBottom: "16px"
    },
    infoLink: {
      marginTop: '1em',
      color: theme.palette.text.primary,
    },
  })
);
