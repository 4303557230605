import React, { ChangeEvent, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as dateFns from "date-fns";

import {
  ClosingDates,
  useGetPublicSettingQuery,
  useGetSeasonByLocationQuery
} from "../api/generated";
import { IAppStore } from "../store/appStore.interface";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Header from "../components/Header";
import { useGlobalStyles } from "../styles/global";
import Typography from "@material-ui/core/Typography";
import Footer from "../components/Footer";
import { Alert } from "@material-ui/lab";
import DatePicker from "../components/DatePicker";
import { parseISO } from "date-fns";
import LoadingContainer from "../components/LoadingContainer";

type DateRangeProps = {
  store: IAppStore;
};

type PublicSettingAvailableDurations = Array<{
  value: number;
  title: string;
}>;

export default function DateRange({ store }: DateRangeProps) {
  const history = useHistory();

  const startDateStore = store.getStartDate() ?? null;
  const endDateStore = store.getEndDate() ?? null;
  const durationStore = store.getDuration() ?? 0;

  const initialStartDate = startDateStore
    ? parseISO(startDateStore)
    : undefined;
  const initialEndDate = endDateStore
    ? parseISO(endDateStore)
    : undefined;
  const initialDuration = durationStore ? (durationStore) : 0;

  const [currentStartDate, setCurrentStartDate] = useState<Date | undefined>(
    initialStartDate
  );
  const [currentEndDate, setCurrentEndDate] = useState<Date | undefined>(
    initialEndDate
  );
  const [currentDuration, setCurrentDuration] = useState<number>(
    initialDuration
  );
  const [dateConfirmed, setDateConfirmed] = useState<boolean>(false);
  const [isCurrentDateRangeValid, setIsCurrentDateRangeValid] = useState<
    boolean
  >(true);

  const [closingDates, setClosingDates] = useState<
    Array<
      { __typename?: "closingDates" } & Pick<
        ClosingDates,
        "id" | "locationId" | "closedDate" | "description"
      >
    >
  >();

  if (!store.getLocation()) {
    history.replace("/");
  }

  const classes = {
    ...useGlobalStyles(),
    ...useGlobalStyles(),
    ...useStyles()
  };

  useEffect(() => {
    if (dateConfirmed) {
      history.push("/products");
    }
  }, [dateConfirmed, history]);

  /**
   * GraphQL
   */
  const {
    data: seasonData,
    loading: seasonLoading,
    error: seasonError
  } = useGetSeasonByLocationQuery({
    variables: { locationId: store.getLocation()?.id },
    skip: store.getLocation()?.id === undefined
  });

  const { data: publicSettingResponse } = useGetPublicSettingQuery({
    variables: { key: "durations-available" },
    skip: !seasonData
  });
  const availableDurationsResponse =
    publicSettingResponse?.publicSettings_by_pk?.value ?? "[]";
  const availableDurations: PublicSettingAvailableDurations =
    JSON.parse(availableDurationsResponse) ?? [];

  const { data: publicSettingContactUrlResponse } = useGetPublicSettingQuery({
    variables: { key: "contact-url" }
  });
  const contactUrl =
    publicSettingContactUrlResponse?.publicSettings_by_pk?.value ?? "";

  useEffect(() => {
    setClosingDates(seasonData?.closingDates);
  }, [seasonData]);

  useEffect(() => {
    setIsCurrentDateRangeValid(true);

    const reservationDays = dateFns.eachDayOfInterval({
      start: currentStartDate ?? dateFns.startOfDay(new Date()),
      end: currentEndDate ?? dateFns.startOfDay(new Date())
    });

    reservationDays.map(date => {
      closingDates?.map(({ closedDate: value }) => {
        const closedDate = parseISO(value);

        if (dateFns.isSameDay(closedDate, date)) {
          setIsCurrentDateRangeValid(false);
        }

        return null;
      });

      seasonData?.seasons.map(season => {
        const start = dateFns.parseISO(season.startDate);
        const end = dateFns.parseISO(season.endDate);

        if (!dateFns.isWithinInterval(date, { start, end })) {
          setIsCurrentDateRangeValid(false);
        }

        return null;
      });

      return null;
    });
  }, [currentStartDate, currentEndDate, closingDates, seasonData]);

  if (seasonError) return <p>Error :(</p>;

  /**
   * Handlers
   */

  const handleConfirmDate = async () => {
    if (currentStartDate && currentDuration) {
      store.setStartDate(dateFns.format(currentStartDate, "yyyy-MM-dd"));
      store.setDuration(currentDuration);
      store.confirmReservationDate();
      setDateConfirmed(true);
    }
  };

  const handleDateChange = async (date: Date): Promise<void> => {
    const duration = currentDuration < 2 ? 0 : currentDuration - 1;

    setCurrentStartDate(date);
    setCurrentEndDate(dateFns.addDays(date, duration));
  };

  const handleDurationChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const value = parseFloat(String(event.target.value));

    if (currentStartDate) {
      setCurrentDuration(value);

      setCurrentEndDate(
        dateFns.addDays(currentStartDate, value < 2 ? 0 : value - 1)
      );
    }
  };

  return (
    <>
      <Header store={store} />
      <Container component="main" maxWidth="md" className={classes.root}>
        <Box className={classes.content}>
          {seasonLoading ? (
            <LoadingContainer />
          ) : (
            <>
              <Typography variant="h1">Datum & Dauer</Typography>

              <Box className={classes.datePickerContainer}>
                <DatePicker
                  locationId={store.getLocation()?.id ?? ""}
                  onSelect={handleDateChange}
                  startDate={currentStartDate}
                  endDate={currentEndDate}
                />
              </Box>

              {currentStartDate && (
                <>
                  {(currentStartDate && !isCurrentDateRangeValid) && (
                    <>
                      <Alert severity="error" className={classes.errorMessage}>
                        Du hast Tage gewählt, an welchen der Shop geschlossen ist. Bitte
                        wähle einen gültigen Zeitraum, ändere die Filiale oder kontaktiere
                        uns per Email oder Telefon.&nbsp;
                        {contactUrl && (
                          <a
                            href={contactUrl}
                            target={"_blank"}
                            rel="noopener noreferrer"
                          >
                            Kontaktdaten
                          </a>
                        )}
                      </Alert>
                    </>
                  )}
                  
                  <Box className={classes.form}>
                    <Typography variant={"h5"} className={classes.durationHeader}>
                      Wie lange möchtest Du reservieren?
                    </Typography>

                    <FormControl variant="filled" fullWidth className={classes.durationSelect}>
                      <Select
                        value={currentDuration?.toString(10)}
                        onChange={handleDurationChange}
                        label="Dauer"
                        fullWidth
                        
                      >
                        {availableDurations.map(duration => (
                          <MenuItem
                            key={duration.title + "-" + duration.value}
                            value={duration.value}
                          >
                            {duration.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Container>

      {(isCurrentDateRangeValid && ((currentDuration ?? 0) > 0)) && (
        <Footer store={store}>
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleConfirmDate()}
              className={classes.primaryButton}
              disabled={!isCurrentDateRangeValid}
            >
              Produkte wählen
            </Button>
          </>
        </Footer>
      )}
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: theme.spacing(6),
      height: '100%',
      backgroundImage: 'url(/theme/img/bg/BP-WB_Schlaraffenland_small_sRGB.png)',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      padding: 0,

      "& .MuiPickersStaticWrapper-staticWrapperRoot": {
        minWidth: "auto",
        background: 'transparent',
      },
      "& .MuiPickersBasePicker-pickerView": {
        minWidth: "auto",
        maxWidth: "auto"
      }
    },
    content: {
      padding: theme.spacing(2),
      position: 'absolute',
      height: '100%',
      textAlign: 'center',
      width: '100%',
    },
    form: {
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      color: theme.palette.primary.main,
      padding: theme.spacing(2)
    },
    wrapperHighlightedDay: {},
    highlightedDay: {
      '& .MuiPickersDay-day': {
        backgroundColor: theme.palette.primary.main,
      },
      "& .MuiPickersDay-dayDisabled": {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.info.contrastText
      }
    },
    durationSelect: {
      padding: 0,
      "& .MuiInputBase-input": {
        padding: "0.9em",
        borderWidth: '1px'
      },
      '& .MuiInputBase-colorSecondary': {
        backgroundColor: theme.palette.primary.main + '!important',
        borderColor: theme.palette.secondary.main + '!important',
        color: theme.palette.secondary.main + '!important',
        borderWidth: '1px'
      },
      '& .MuiSelect-iconOutlined': {
        color: theme.palette.secondary.main
      }
    },
    durationHeader: {
      marginTop: "6px"
    },
    datePickerContainer: {
      minHeight: "305px",
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      color: theme.palette.primary.main,
    },
    errorMessage: {
      padding: "1em"
    }
  })
);
