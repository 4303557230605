import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useGlobalStyles } from '../styles/global';

export default function LoadingContainer() {
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  return (
    <Box className={classes.loadingContainer}>
      <CircularProgress />
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingContainer: {
      width: '100%',
      height: '100%',
      minHeight: '500px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  })
);