import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export const useGlobalStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      paddingBottom: '50px',
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(1),
      },
      marginBottom: theme.spacing(10),
    },
    appBar: {
      top: 'auto',
      bottom: 0,
      '& .MuiButtonBase-root': {
        marginTop: '1em',
      },
      '& .MuiCheckbox-root': {
        marginTop: '0em',
      },
      padding: theme.spacing(2),
      paddingTop: 0,
      backgroundColor: 'transparent',
    },
    divider: {
      border: `1px solid ${theme.palette.primary.main}`,
      marginTop: '1em',
      marginBottom: '1em'
    },
    primaryButton: {
      height: '4em',
    },
  }),
);