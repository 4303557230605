import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar } from "@material-ui/core";

import { IAppStore } from '../store/appStore.interface';
import { useGlobalStyles } from '../styles/global';
import MiniBasket from './MiniBasket';
import { Alert, AlertTitle } from '@material-ui/lab';

type HeaderProps = {
  store: IAppStore,
}

export default function Header({ store }: HeaderProps) {
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const allLocationMessages = store.getLocation()?.messages ?? [];
  const currentLocationMessages = allLocationMessages.filter((msg) => {
    const bookingStartDate = new Date(store.getStartDate() ?? new Date());
    const bookingEndDate = new Date(store.getEndDate() ?? new Date());
    const startDate = new Date(msg.startDate);
    const endDate = new Date(msg.endDate);

    const isStartDateInRange = (bookingStartDate >= startDate && bookingStartDate <= endDate);
    const isEndDateInRange = (bookingEndDate >= startDate && bookingEndDate <= endDate);

    return isStartDateInRange || isEndDateInRange;
  });

  const locationMessages = currentLocationMessages.map((msg) => (
    <>
      <Alert key={msg.location.name} severity={'info'}>
        {msg.title && (<AlertTitle>{msg.title}</AlertTitle>)}

        {msg.message}
      </Alert>
    </>)
  ) ?? [];

  return (
    <>
      {store.getLocation()?.id && (
        <>
          {locationMessages.length ? locationMessages : <></>}
          <AppBar position="relative" color="default" className={classes.appBar}>
            <MiniBasket store={store} />
          </AppBar>
        </>
      )
      }
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: 0,
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '4em',
      marginBottom: '2em',
    },
  }),
);
