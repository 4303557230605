import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Fade
} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import MailRounded from '@material-ui/icons/MailRounded';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {IAppStore} from '../store/appStore.interface';
import {useGlobalStyles} from '../styles/global';
import { useBookingRequestSubscription, useGetPublicSettingQuery } from "../api/generated";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: theme.spacing(7),
      height: `calc(100% - ${theme.spacing(4)}px)`,
      backgroundImage: 'url(/theme/img/bg/BP-WB_Slopestyle_small_sRGB.png)',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      padding: 0
    },
    content: {
      padding: theme.spacing(2),
      position: 'absolute',
      height: '100%',
      textAlign: 'left',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.8)'
    },
    body: {
      textAlign: 'center',
      '& a': {
        color: '#000',
        textDecoration: 'underline'
      },
    },
    infoText: {
      color: theme.palette.warning.main
    },
    loadingIcon: {
      marginBottom: '2em',
    }
  }),
);

type ConfirmationProps = {
  store: IAppStore,
}

export default function Confirmation({store}: ConfirmationProps ) {
  const history = useHistory();

  const {data, loading, error} = useBookingRequestSubscription({
    variables: {
      id: store.getBookingRequest().id,
    }
  });

  const { data: publicSettingResponse } = useGetPublicSettingQuery({
    variables: { key: 'faq-url'},
    skip: !data
  });
  const faqUrl = publicSettingResponse?.publicSettings_by_pk?.value ?? '';

  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const handleNewReservation = () => {
    store.resetReservationDate();
    store.cleanupAfterSuccess();

    history.push('/');
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <>
      <Container component="main" maxWidth="md" className={classes.root}>
        <Box className={`${classes.content} ${classes.body}`}>
          {
            (loading || (data?.bookingRequest_by_pk?.success === null)) && (
              <>
                <Typography variant="h1">
                  Reservierung wird gesendet...
                </Typography>

                <Fade
                  in={loading || !data?.bookingRequest_by_pk?.success}
                  style={{
                    transitionDelay: '0ms',
                  }}
                  unmountOnExit
                >
                  <CircularProgress
                    color='primary'
                    className={classes.loadingIcon}
                    size={'9em'}
                  />
                </Fade>

                <Typography variant="subtitle2">
                  Wir prüfen deine Reservierung.<br />
                  Dies kann einen Moment dauern!
                </Typography>
              </>
            )
          }

          {
            (error || (data?.bookingRequest_by_pk?.success === false)) && (
              <>
              <Typography variant="h1">
                Autsch!
              </Typography>

              <Typography variant="subtitle2" color={"error"}>
                <ErrorOutlineIcon
                  style={{ fontSize: '9em' }}
                  color='error'
                />
                <br />

                Bei Deiner Reservierung gab es ein Problem.<br /><br />
                Wahrscheinlich hat Dir gerade jemand das letzte Bike weggeschnappt.<br/><br/>
                <strong>Bitte versuche es erneut!</strong>
              </Typography>

              <Typography variant="h5" className={classes.body}>
                Fragen?
              </Typography>

              {faqUrl && (
                <a target="_blank" rel="noopener noreferrer" href={faqUrl}>Hier geht&apos;s zu den FAQ&apos;s</a>
              )}
            </>
            )
          }

          {
            data?.bookingRequest_by_pk?.success && (
              <>
                <Typography variant="h1">
                  {/*@todo: Make setting for tenant!*/}
                  Verifizierungs-E-Mail gesendet...
                </Typography>

                <Typography variant="subtitle2">

                  {/*@todo: Make setting for tenant!*/}
                  <MailRounded
                    style={{ fontSize: '9em' }}
                    color='primary'
                  />
                  <br />

                  {/*<CheckCircleIcon*/}
                  {/*  style={{ fontSize: '9em' }}*/}
                  {/*  color='primary'*/}
                  {/*/>*/}
                  {/*<br />*/}

                  Deine Reservierung ist bei uns eingetroffen.<br /><br />
                  <Box className={classes.infoText}>
                    <strong>Bitte bestätige diese noch mit dem Link, den wir an deine E-Mail Adresse gesendet haben.</strong><br />
                  </Box>
                </Typography>

                <Typography variant="h5" className={classes.body}>
                  Fragen?
                </Typography>

                {faqUrl && (
                  <a target="_blank" rel="noopener noreferrer" href={faqUrl}>Hier geht&apos;s zu den FAQ&apos;s</a>
                )}
              </>
            )
          }
        </Box>
      </Container>

      <AppBar position={store.getFooterPosition()} color="default" className={classes.appBar}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleNewReservation}
        >
          Neue Reservierung
        </Button>
      </AppBar>
    </>
  );
}
