import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {IAppStore} from '../store/appStore.interface';
import { AppBar, Box, Button, Container, Grid, TextField } from '@material-ui/core';
import {Link as RouterLink } from 'react-router-dom';
import Header from "../components/Header";
import {useGlobalStyles} from '../styles/global';
// import * as dateFns from "date-fns";

type AddressProps = {
  store: IAppStore,
}

export default function ProductTypes({store}: AddressProps) {
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const customer = store.getCustomer();

    store.setCustomer({
      ...customer,
      [event.target.name]: event.target.value
    });
  };

  // const handleBirthdateChange = async (date: Date): Promise<void> => {
  //   if (dateFns.isValid(date)) {
  //     const customer = store.getCustomer();
  //
  //     store.setCustomer({
  //       ...customer,
  //       birthdate: dateFns.format(date, "yyyy-MM-dd")
  //     });
  //   }
  // };

  return (
    <>
      <Header store={store} />
      <Container component="main" maxWidth="md" className={classes.root}>
        <Box className={classes.paper}>
          <Typography variant="h1">
            Kontaktdaten
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                name={"firstname"}
                label="Vorname"
                variant="outlined"
                value={store.getCustomer().firstname ?? ''}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={"name"}
                label="Nachname"
                variant="outlined"
                value={store.getCustomer().name ?? ''}
                onChange={handleChange}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <TextField
                name={"street"}
                label="Straße"
                variant="outlined"
                fullWidth={true}
                value={store.getCustomer().street ?? ''}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name={"streetnumber"}
                label="Hnr."
                variant="outlined"
                fullWidth={true}
                value={store.getCustomer().streetnumber ?? ''}
                onChange={handleChange}
                size="small"
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                name={"zipCode"}
                label="PLZ"
                variant="outlined"
                fullWidth={true}
                value={store.getCustomer().zipCode ?? ''}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                name={"city"}
                label="Ort"
                variant="outlined"
                fullWidth={true}
                value={store.getCustomer().city ?? ''}
                onChange={handleChange}
                size="small"
              />
            </Grid>
          </Grid>

          <TextField
            name={"countryCode"}
            label="Land"
            variant="outlined"
            fullWidth={true}
            value={store.getCustomer().countryCode ?? ''}
            onChange={handleChange}
            size="small"
          />


          <TextField
            name={"email"}
            label="E-Mail"
            variant="outlined"
            fullWidth={true}
            value={store.getCustomer().email ?? ''}
            onChange={handleChange}
            size="small"
          />

          <TextField
            name={"phone"}
            label="Telefonnummer"
            variant="outlined"
            fullWidth={true}
            value={store.getCustomer().phone ?? ''}
            onChange={handleChange}
            size="small"
          />

          <TextField type={'hidden'} value={store.getCustomer().birthdate} />
        </Box>
      </Container>
      <AppBar position={store.getFooterPosition()} color="default" className={classes.appBar}>
        <Button
          className={classes.primaryButton}
          variant="contained"
          color="primary"
          disabled={!store.isCustomerValid()}
          fullWidth
          component={RouterLink}
          to={"/summary"}
        >
          Zur Übersicht
        </Button>
      </AppBar>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(1.5),
      }
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
  }),
);