import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IAppStore } from '../store/appStore.interface';
import { useGetPoductTypesOverviewQuery } from '../api/generated';
import { Box, Button, Container, Divider, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useGlobalStyles } from "../styles/global";
import Header from "../components/Header";
import LoadingContainer from '../components/LoadingContainer';
import { Alert, AlertTitle } from '@material-ui/lab';

type ProductTypesProps = {
  store: IAppStore,
}

export default function ProductTypes({ store }: ProductTypesProps) {
  const history = useHistory();

  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  if (store.getCurrentProduct().id !== null) {
    store.setCurrentProduct({ id: null });
  }

  const { data, loading, error } = useGetPoductTypesOverviewQuery({
    variables: {
      startDate: store.getStartDate(),
      endDate: store.getEndDate(),
      duration: store.getDuration(),
      locationId: store.getLocation()?.id
    }
  });

  if (error) return <p>Error :(</p>;

  const productTypes = data?.productTypes
    // Do not show products which are not configured for this dateRange / location
    .filter(type => null != type.availabilities_aggregate?.aggregate?.min?.sumAvailableBikes)
    .map(
      type => ({
        id: type.id,
        name: type.name,
        description: type.category,
        longDescription: type.description,
        available: type.availabilities_aggregate?.aggregate?.min?.sumAvailableBikes > 0,
        price: type.prices_aggregate?.aggregate?.min?.minBasePricePerDay,
        image: type.image
      })
    ) ?? [];

  const onSelectProductType = (productTypeId: string) => {
    const currentProduct = store.getCurrentProduct();

    store.setCurrentProduct({
      ...currentProduct,
      id: null,
      data: undefined,
    });

    history.push("/product/" + productTypeId);
  };

  const isAlertShown = (): boolean => {
    const festivalStart = new Date('2023-05-18');
    const festivalEnd = new Date('2023-05-21');

    const bookingStart = new Date(store.getStartDate() ?? new Date());
    const bookingEnd = new Date(store.getEndDate() ?? new Date());

    // Check if date of booking intersects with the festival
    return bookingStart <= festivalEnd && bookingEnd >= festivalStart;
  }

  return (
    <>
      <Header store={store} />
      <Container component="main" maxWidth="md" className={classes.root}>
        <Box className={classes.content}>
          {loading ? (
            <LoadingContainer />
          ) : (
            <>
              <Typography variant="h1">
                Produkt auswählen
              </Typography>

              {isAlertShown() && (
                <>
                  <Alert severity="warning" className={classes.festivalInfo}>
                    <AlertTitle>Achtung: iXS Dirt Masters Festival</AlertTitle>
                    Vom 18. bis 21. Mai findet im Bikepark Winterberg das iXS Dirt Masters Festival statt!
                    An diesen Tagen ist mit erhöhten Wartezeiten am Lift zu rechnen.
                    Bitte beachtet auch, dass es an einigen Tagen vor und nach sowie natürlich auch während dem Festival zu
                    Streckeneinschränkungen kommen kann.
                    Alle Infos dazu findest du direkt auf <a href={'https://www.bikepark-winterberg.de'} target={'_blank'} rel="noopener noreferrer">unserer Homepage</a>.
                  </Alert>
                </>
              )}

              <Divider className={classes.divider} />

              {productTypes.map(type => (
                <Box key={type.id}>
                  <Grid
                    container
                    spacing={3}
                    className={type.available ? classes.enabled : classes.disabled}
                  >
                    <Grid item xs={12}>
                        <Typography variant={"h5"} className={classes.productTitle}>{type.description} {type.name}</Typography>
                        <Typography variant={"body2"} className={classes.productSubtitle}>{type.longDescription}</Typography>
                        <Typography variant={"body2"}></Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: 'center'}}>
                      <img className={classes.productTypeThumbnail} src={type.image} alt={type.name} style={{maxWidth: '66%'}}/>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      disabled={!type.available}
                      className={classes.productSelectButton}
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => onSelectProductType(type.id)}
                    >
                      {
                        type.available ? (
                          <>
                            Reservieren
                          </>
                        ) : (
                          <>Ausgebucht</>
                        )
                      }
                    </Button>
                  </Grid>

                  <Divider className={classes.divider} />
                </Box>
              ))}
            </>
          )}

        </Box>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: theme.spacing(5),
      height: `calc(200% - ${theme.spacing(4)}px)`,
      backgroundImage: 'url(/theme/img/bg/BP-WB_Pumptrack_small_sRGB.png)',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      padding: 0
    },
    content: {
      padding: theme.spacing(2),
      position: 'absolute',
      height: '100%',
      textAlign: 'center',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.8)'
    },
    header: {
      margin: theme.spacing(6, 0, 3),
    },
    productTypeThumbnail: {
      width: '100%',
      height: 'auto',
    },
    enabled: {},
    disabled: {
      opacity: 0.6,
      filter: 'grayscale(100%)',
    },
    productSelectButton: {
      marginTop: theme.spacing(1),
    },
    productTitle: {
      margin: 0,
      padding: 0,
    },
    productSubtitle: {
      marginBottom: '8px',
    },
    festivalInfo: {
      fontSize: '0.9em',
      marginBottom: '16px',
    }
  }),
)