import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Container, Typography } from "@material-ui/core";

import { useVerifyOrderMutation } from "../api/generated";
import { useGlobalStyles } from "../styles/global";
import LoadingContainer from "../components/LoadingContainer";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default function VerifyOrder() {
  const classes = {
    ...useGlobalStyles(),
    ...useStyles()
  };

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  /**
   * GraphQL
   */
  const [
    sendVerifyOrderRequest,
    {
      data: sendVerifyOrderResult,
      loading: sendVerifyOrderLoading,
      error: sendVerifyOrderError
    }
  ] = useVerifyOrderMutation();

  useEffect(() => {
    const submit = async () => {
      if (id?.length) {
        try {
          await sendVerifyOrderRequest({ variables: { id: id } });
        } catch (e) {
          console.error(e);
        }
      }
    };

    submit();
  }, [id, sendVerifyOrderRequest]);

  if (sendVerifyOrderError) {
    return (
      <>
        <Container component="main" maxWidth="md">
          <Box className={classes.paper}>
            <Box className={classes.header}>
              <img
                className={classes.headerImage}
                src={"/theme/img/logo.png"}
                alt={"Logo"}
              />
            </Box>
            <Box className={classes.content}>
              Es ist ein Fehler aufgetreten. Bitte wende dich an unseren
              Support.
            </Box>
          </Box>
        </Container>
      </>
    );
  }

  console.log(sendVerifyOrderResult);

  return (
    <>
      <Container component="main" maxWidth="md">
        <Box className={classes.paper}>
          <Box className={classes.content}>
            <Box className={classes.header}>
              <img
                className={classes.headerImage}
                src={"/theme/img/logo.png"}
                alt={"Logo"}
              />
            </Box>

            {sendVerifyOrderLoading ? (
              <LoadingContainer />
            ) : (
              <>
                <CheckCircleIcon
                  style={{ fontSize: '9em' }}
                  color='primary'
                />
                <br />

                <Typography variant="h1" className={classes.headerText}>
                  Deine Reservierung wurde erfolgreich bestätigt.
                </Typography>

                <Typography variant="body1" className={classes.headerText}>
                  Überprüfe dein Postfach deiner E-Mail Adresse. Dort findest du
                  eine Bestätigung mit allen wichtigen Informationen.
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      textAlign: "center",
      width: "100%"
    },
    header: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      margin: "0 auto"
    },
    headerImage: {
      width: "30%"
    },
    headerText: {
      padding: "0.5em 0",
      textTransform: "uppercase"
    }
  })
);
