import React, { FC, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ShoppingBasket } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { IAppStore } from '../store/appStore.interface';
import { useGlobalStyles } from '../styles/global';
import { formatDateRange } from '../helpers/date';
import BackButton from './BackButton';

type MiniBasketProps = {
  store: IAppStore,
}

interface IResetWarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}

const ResetWarningModal: FC<IResetWarningModalProps> = ({ onClose, isOpen, onAccept }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Datum / Bikepark ändern</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Achtung: Deine aktuelle Reservierung geht durch den Wechsel des Datums und/oder des Bikeparks verloren.<br />
          Möchtest du wirklich wechseln?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Nein
        </Button>
        <Button onClick={onAccept} color="secondary" autoFocus>
          Ja, ändern
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function MiniBasket({ store }: MiniBasketProps) {
  const history = useHistory();
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const [resetWarningOpen, setResetWarningOpen] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [currentProductId, setCurrentProductId] = React.useState<string | null>(store.getCurrentProduct()?.id ?? null);
  const amountBasketItems = store.getBasket().items.length;
  // const currentCustomer = store.getCustomer();
  const showBasketIcon = amountBasketItems > 0 && store.isCustomerValid();

  useEffect(() => {
    if (currentProductId !== null || amountBasketItems > 0) {
      setIsDisabled(true);
    }
  }, [currentProductId, amountBasketItems]);

  useEffect(() => {
    setCurrentProductId(store.getCurrentProduct().id);
  }, [store])

  const navigateTo = (route: string) => {
    history.push(route);
  }

  const handleResetRequest = () => {
    setResetWarningOpen(true);
  };

  const handleClose = () => {
    setResetWarningOpen(false);
  };

  const handleResetDate = () => {
    store.resetReservationDate();
    history.push('/');
  };

  return (
    <>
      <ResetWarningModal isOpen={resetWarningOpen} onClose={handleClose} onAccept={handleResetDate} />
      <Box className={classes.root}>
        <Grid container>
          <Grid item xs={1}>
            <Box className={`${classes.menuItem} ${classes.backButtonCol} ${classes.menuItemCenterAlign}`}>
              <BackButton store={store} />
            </Box>
          </Grid>
          <Grid item xs={4}>
            {store.getLocation() && (
              <>
                <Box
                  className={`${classes.menuItem} ${classes.menuItemCenterAlign}`}
                  onClick={() => isDisabled ? handleResetRequest() : navigateTo('/')}
                >
                  <Typography variant={'subtitle1'} className={classes.infoTitle}>
                    {store.getLocation()?.name}
                  </Typography>

                </Box>
              </>
            )}
          </Grid>
          <Grid item xs={5}>
            {store.isReservationDateValid() && (
              <Box
                className={`${classes.menuItem} ${classes.menuItemCenterAlign}`}
                onClick={() => isDisabled ? handleResetRequest() : navigateTo('/date-range')}
              >
                <Typography variant={'subtitle1'} className={classes.infoTitle}>
                  {'string' === typeof store.getStartDate() && formatDateRange(store.getStartDate() ?? new Date(), store.getEndDate() ?? new Date())}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={2}>
            {showBasketIcon && (
              <>
                <Box
                  className={`${classes.menuItem} ${classes.menuItemCenterAlign} ${classes.basketCol}`}
                  onClick={() => navigateTo('/summary')}
                >
                  <ShoppingBasket fontSize={'default'} />
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      padding: theme.spacing(2),
    },
    menuItem: {
      fontSize: '1em',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      minWidth: '30%',
      borderRight: '1px dotted grey'
    },
    menuItemRightAlign: {
      alignItems: 'flex-end',
      borderRight: 'none'
    },
    menuItemCenterAlign: {
      alignItems: 'center',
    },
    backButtonCol: {
      width: '3em',
      marginLeft: '-1em'
    },
    basketCol: {
      minWidth: '10%',
      textAlign: 'right',
    },
    infoTitle: {},
    disabled: {
      color: theme.palette.grey['A700']
    }
  }),
);
