import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {IAppStore} from '../store/appStore.interface';
import {AppBar} from "@material-ui/core";
import {useGlobalStyles} from '../styles/global';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      height: '40px',
      width: 'auto',
    },
    contentContainer: {

    },
  }),
);

type FooterProps = {
  store: IAppStore,
  children?: JSX.Element,
}

export default function Footer({store, children}: FooterProps) {
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  return (
    <>
      <AppBar position={store.getFooterPosition()} color="default" className={classes.appBar}>
        {children && (
          <>
          {children}
          </>
        )}
      </AppBar>
    </>
  );
}
