import React, { useEffect, useState } from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {
  Box, Button,
  Container,
  Typography
} from "@material-ui/core";
import {useHistory} from 'react-router-dom';

import { useGetLocationsQuery } from "../api/generated";
import { IAppStore, ILocation } from "../store/appStore.interface";
import Header from "../components/Header";
import {useGlobalStyles} from "../styles/global";
import Footer from '../components/Footer';
import LocationSelector from '../components/LocationSelector';

type LocationProps = {
  store: IAppStore,
}

export default function Start({store}: LocationProps): React.ReactNode {
  const history = useHistory();
  const [location, setLocation] = useState<ILocation>(store.getLocation());
  const locationSearch = new URLSearchParams(window.location.search);
  if (location && locationSearch.get('locationId')) {
    store.setLocation(location)
    history.push('/date-range');
  }

  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  /**
   * GraphQL
   */
  const {data: locationsData, loading: locationsLoading, error: locationsError} = useGetLocationsQuery();
  const allLocations = locationsData?.locations ?? [];
  const publicLocations = allLocations.filter((location) => location.stealthMode !== true);

  useEffect(() => {
    if (locationSearch.get('locationId')) {
      const hiddenLocation = allLocations.find((location) => location.id === locationSearch.get('locationId'));

      if (hiddenLocation) {
        setLocation(hiddenLocation);
        store.setLocation(hiddenLocation);

        return;
      }
    }

    if (1 === publicLocations.length && !store.getLocation()) {
      setLocation(publicLocations[0]);
      store.setLocation(publicLocations[0]);
    }
  }, [allLocations, locationSearch, publicLocations, store]);

  if (locationsLoading || locationsError) {
    return <></>
  }

  /**
   * Handlers
   */
  const handleConfirmLocation = (location: ILocation) => {
    setLocation(location);

    store.setLocation(location)
    history.push('/date-range');
  };

  const handleSubmitLocation = () => {
    if (location) {
      store.setLocation(location)
      history.push('/date-range');
    }
  }

  return (
    <>
      <Header store={store} />
      <Container component="main" maxWidth="md" className={classes.root}>
        <Box className={classes.paper}>
          <Box className={classes.content}>
            <Box className={classes.header}>
              <img
                className={classes.headerImage}
                src={'/theme/img/logo.png'}
                alt={'Logo'}
              />
            </Box>
            <Box className={classes.text}>
              <Typography variant="h1" className={classes.headerText}>
                Reservieren
              </Typography>
              {publicLocations.length > 1 ? (
                <>
                  <Typography variant="h2">
                    Wo möchtest du starten?
                  </Typography>
                </>
              ) : (
                <>
                  Willkommen bei unserer Online-Reservierung.<br />
                  Klicke &quot;Start&quot;, um deine Aufenthaltsdauer und aus unseren Angeboten zu wählen.
                </>
              )}

              <LocationSelector
                activeLocation={location}
                locations={publicLocations}
                onSelect={handleConfirmLocation}
              />
            </Box>
          </Box>
        </Box>
      </Container>

      {
        location
        && (
          <Footer store={store}>
            <>
              <Button
                className={classes.primaryButton}
                variant="contained"
                color="primary"
                onClick={() => handleSubmitLocation()}
              >
                {publicLocations.length > 1 ? (
                  <>Standort ({location.name}) wählen</>
                ) : (
                  <>Start</>
                )}

              </Button>
            </>
          </Footer>
        )
      }
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: theme.spacing(4),
      height: `calc(100% - ${theme.spacing(4)}px)`,
      backgroundImage: 'url(/theme/img/bg/BP-WB_Downhill_small_sRGB.png)',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      padding: 0
    },
    content: {
      padding: theme.spacing(2),
      position: 'absolute',
      height: '100%',
      textAlign: 'center',
      width: '100%',
    },
    text: {
      fontFamily: 'Dotimatrix, sans-serif',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      padding: theme.spacing(2),
      color: theme.palette.primary.main,
      fontSize: '1.4em'
    },
    dark: {
      color: theme.palette.primary.main + '!important',
    },
    header: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      margin: '0 auto',
    },
    headerImage: {
      width: '80%',
    },
    headerText: {
      padding: '0.5em 0',
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
    }
  }),
);
