import React from 'react';

import { IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { useGlobalStyles } from '../styles/global';
import { IAppStore } from '../store/appStore.interface';

type BackButtonProps = {
  store: IAppStore,
}

export default function BackButton({ store }: BackButtonProps) {
  const history = useHistory();

  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const handleClick = () => {
    history.goBack();
  }

  const disabled = history.location.pathname === '/' || (history.location.pathname === '/products' && store.getBasket().items.length > 0);

  return (
    <IconButton
      aria-label="Zurück"
      size={'small'}
      className={classes.root}
      onClick={handleClick}
      disabled={disabled}
    >
      <ArrowBack />
    </IconButton>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
    },
  })
);