import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  uuid: any;
  date: any;
  numeric: any;
  bigint: any;
  timestamptz: any;
  jsonb: any;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "bookingRequest" */
export type BookingRequest = {
  __typename?: 'bookingRequest';
  id: Scalars['uuid'];
  success?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bookingRequest". All fields are combined with a logical 'AND'. */
export type BookingRequest_Bool_Exp = {
  _and?: Maybe<Array<BookingRequest_Bool_Exp>>;
  _not?: Maybe<BookingRequest_Bool_Exp>;
  _or?: Maybe<Array<BookingRequest_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  success?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "bookingRequest" */
export enum BookingRequest_Constraint {
  /** unique or primary key constraint on columns "id" */
  BookingRequestPkey = 'bookingRequest_pkey'
}

/** input type for inserting data into table "bookingRequest" */
export type BookingRequest_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  request?: Maybe<Scalars['jsonb']>;
};

/** response of any mutation on the table "bookingRequest" */
export type BookingRequest_Mutation_Response = {
  __typename?: 'bookingRequest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BookingRequest>;
};

/** on_conflict condition type for table "bookingRequest" */
export type BookingRequest_On_Conflict = {
  constraint: BookingRequest_Constraint;
  update_columns: Array<BookingRequest_Update_Column>;
  where?: Maybe<BookingRequest_Bool_Exp>;
};

/** Ordering options when selecting data from "bookingRequest". */
export type BookingRequest_Order_By = {
  id?: Maybe<Order_By>;
  success?: Maybe<Order_By>;
};

/** select columns of table "bookingRequest" */
export enum BookingRequest_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Success = 'success'
}

/** Streaming cursor of the table "bookingRequest" */
export type BookingRequest_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BookingRequest_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BookingRequest_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** placeholder for update columns of table "bookingRequest" (current role has no relevant permissions) */
export enum BookingRequest_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "closingDates" */
export type ClosingDates = {
  __typename?: 'closingDates';
  closedDate: Scalars['date'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  locationId: Scalars['uuid'];
};

/** order by aggregate values of table "closingDates" */
export type ClosingDates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ClosingDates_Max_Order_By>;
  min?: Maybe<ClosingDates_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "closingDates". All fields are combined with a logical 'AND'. */
export type ClosingDates_Bool_Exp = {
  _and?: Maybe<Array<ClosingDates_Bool_Exp>>;
  _not?: Maybe<ClosingDates_Bool_Exp>;
  _or?: Maybe<Array<ClosingDates_Bool_Exp>>;
  closedDate?: Maybe<Date_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "closingDates" */
export type ClosingDates_Max_Order_By = {
  closedDate?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
};

/** order by min() on columns of table "closingDates" */
export type ClosingDates_Min_Order_By = {
  closedDate?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "closingDates". */
export type ClosingDates_Order_By = {
  closedDate?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  locationId?: Maybe<Order_By>;
};

/** select columns of table "closingDates" */
export enum ClosingDates_Select_Column {
  /** column name */
  ClosedDate = 'closedDate',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId'
}

/** Streaming cursor of the table "closingDates" */
export type ClosingDates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ClosingDates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ClosingDates_Stream_Cursor_Value_Input = {
  closedDate?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};


/** Messages which are displayed to customers on the frontend */
export type LocationMessages = {
  __typename?: 'locationMessages';
  endDate: Scalars['date'];
  id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  locationId: Scalars['uuid'];
  message?: Maybe<Scalars['String']>;
  startDate: Scalars['date'];
  title?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "locationMessages" */
export type LocationMessages_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<LocationMessages_Max_Order_By>;
  min?: Maybe<LocationMessages_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "locationMessages". All fields are combined with a logical 'AND'. */
export type LocationMessages_Bool_Exp = {
  _and?: Maybe<Array<LocationMessages_Bool_Exp>>;
  _not?: Maybe<LocationMessages_Bool_Exp>;
  _or?: Maybe<Array<LocationMessages_Bool_Exp>>;
  endDate?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  startDate?: Maybe<Date_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "locationMessages" */
export type LocationMessages_Max_Order_By = {
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** order by min() on columns of table "locationMessages" */
export type LocationMessages_Min_Order_By = {
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "locationMessages". */
export type LocationMessages_Order_By = {
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  locationId?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "locationMessages" */
export enum LocationMessages_Select_Column {
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Message = 'message',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Title = 'title'
}

/** Streaming cursor of the table "locationMessages" */
export type LocationMessages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LocationMessages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LocationMessages_Stream_Cursor_Value_Input = {
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  title?: Maybe<Scalars['String']>;
};

/** columns and relationships of "locations" */
export type Locations = {
  __typename?: 'locations';
  /** An array relationship */
  closingDates: Array<ClosingDates>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  messages: Array<LocationMessages>;
  name: Scalars['String'];
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** An array relationship */
  seasons: Array<Seasons>;
  stealthMode?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "locations" */
export type LocationsClosingDatesArgs = {
  distinct_on?: Maybe<Array<ClosingDates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClosingDates_Order_By>>;
  where?: Maybe<ClosingDates_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsMessagesArgs = {
  distinct_on?: Maybe<Array<LocationMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationMessages_Order_By>>;
  where?: Maybe<LocationMessages_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsSeasonsArgs = {
  distinct_on?: Maybe<Array<Seasons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seasons_Order_By>>;
  where?: Maybe<Seasons_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type Locations_Bool_Exp = {
  _and?: Maybe<Array<Locations_Bool_Exp>>;
  _not?: Maybe<Locations_Bool_Exp>;
  _or?: Maybe<Array<Locations_Bool_Exp>>;
  closingDates?: Maybe<ClosingDates_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  messages?: Maybe<LocationMessages_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  products?: Maybe<Products_Bool_Exp>;
  products_aggregate?: Maybe<Products_Aggregate_Bool_Exp>;
  seasons?: Maybe<Seasons_Bool_Exp>;
  stealthMode?: Maybe<Boolean_Comparison_Exp>;
};

/** Ordering options when selecting data from "locations". */
export type Locations_Order_By = {
  closingDates_aggregate?: Maybe<ClosingDates_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  messages_aggregate?: Maybe<LocationMessages_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
  seasons_aggregate?: Maybe<Seasons_Aggregate_Order_By>;
  stealthMode?: Maybe<Order_By>;
};

/** select columns of table "locations" */
export enum Locations_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StealthMode = 'stealthMode'
}

/** Streaming cursor of the table "locations" */
export type Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locations_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  stealthMode?: Maybe<Scalars['Boolean']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** insert data into the table: "bookingRequest" */
  insert_bookingRequest?: Maybe<BookingRequest_Mutation_Response>;
  /** insert a single row into the table: "bookingRequest" */
  insert_bookingRequest_one?: Maybe<BookingRequest>;
  verifyOrder?: Maybe<VerifyOrderResult>;
};


/** mutation root */
export type Mutation_RootInsert_BookingRequestArgs = {
  objects: Array<BookingRequest_Insert_Input>;
  on_conflict?: Maybe<BookingRequest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BookingRequest_OneArgs = {
  object: BookingRequest_Insert_Input;
  on_conflict?: Maybe<BookingRequest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootVerifyOrderArgs = {
  id: Scalars['String'];
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "productPrices" */
export type ProductPrices = {
  __typename?: 'productPrices';
  basePricePerDay: Scalars['Int'];
  id: Scalars['uuid'];
  insuranceCostsPerDay: Scalars['Int'];
  minDuration: Scalars['numeric'];
  /** An object relationship */
  product: Products;
  productId: Scalars['uuid'];
  protectionCostsPerDay: Scalars['Int'];
};

/** aggregated selection of "productPrices" */
export type ProductPrices_Aggregate = {
  __typename?: 'productPrices_aggregate';
  aggregate?: Maybe<ProductPrices_Aggregate_Fields>;
  nodes: Array<ProductPrices>;
};

export type ProductPrices_Aggregate_Bool_Exp = {
  count?: Maybe<ProductPrices_Aggregate_Bool_Exp_Count>;
};

export type ProductPrices_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ProductPrices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProductPrices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "productPrices" */
export type ProductPrices_Aggregate_Fields = {
  __typename?: 'productPrices_aggregate_fields';
  avg?: Maybe<ProductPrices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProductPrices_Max_Fields>;
  min?: Maybe<ProductPrices_Min_Fields>;
  stddev?: Maybe<ProductPrices_Stddev_Fields>;
  stddev_pop?: Maybe<ProductPrices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductPrices_Stddev_Samp_Fields>;
  sum?: Maybe<ProductPrices_Sum_Fields>;
  var_pop?: Maybe<ProductPrices_Var_Pop_Fields>;
  var_samp?: Maybe<ProductPrices_Var_Samp_Fields>;
  variance?: Maybe<ProductPrices_Variance_Fields>;
};


/** aggregate fields of "productPrices" */
export type ProductPrices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductPrices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "productPrices" */
export type ProductPrices_Aggregate_Order_By = {
  avg?: Maybe<ProductPrices_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ProductPrices_Max_Order_By>;
  min?: Maybe<ProductPrices_Min_Order_By>;
  stddev?: Maybe<ProductPrices_Stddev_Order_By>;
  stddev_pop?: Maybe<ProductPrices_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ProductPrices_Stddev_Samp_Order_By>;
  sum?: Maybe<ProductPrices_Sum_Order_By>;
  var_pop?: Maybe<ProductPrices_Var_Pop_Order_By>;
  var_samp?: Maybe<ProductPrices_Var_Samp_Order_By>;
  variance?: Maybe<ProductPrices_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ProductPrices_Avg_Fields = {
  __typename?: 'productPrices_avg_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "productPrices" */
export type ProductPrices_Avg_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "productPrices". All fields are combined with a logical 'AND'. */
export type ProductPrices_Bool_Exp = {
  _and?: Maybe<Array<ProductPrices_Bool_Exp>>;
  _not?: Maybe<ProductPrices_Bool_Exp>;
  _or?: Maybe<Array<ProductPrices_Bool_Exp>>;
  basePricePerDay?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  insuranceCostsPerDay?: Maybe<Int_Comparison_Exp>;
  minDuration?: Maybe<Numeric_Comparison_Exp>;
  product?: Maybe<Products_Bool_Exp>;
  productId?: Maybe<Uuid_Comparison_Exp>;
  protectionCostsPerDay?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type ProductPrices_Max_Fields = {
  __typename?: 'productPrices_max_fields';
  basePricePerDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  insuranceCostsPerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
  productId?: Maybe<Scalars['uuid']>;
  protectionCostsPerDay?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "productPrices" */
export type ProductPrices_Max_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ProductPrices_Min_Fields = {
  __typename?: 'productPrices_min_fields';
  basePricePerDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  insuranceCostsPerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
  productId?: Maybe<Scalars['uuid']>;
  protectionCostsPerDay?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "productPrices" */
export type ProductPrices_Min_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "productPrices". */
export type ProductPrices_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  product?: Maybe<Products_Order_By>;
  productId?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** select columns of table "productPrices" */
export enum ProductPrices_Select_Column {
  /** column name */
  BasePricePerDay = 'basePricePerDay',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceCostsPerDay = 'insuranceCostsPerDay',
  /** column name */
  MinDuration = 'minDuration',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProtectionCostsPerDay = 'protectionCostsPerDay'
}

/** aggregate stddev on columns */
export type ProductPrices_Stddev_Fields = {
  __typename?: 'productPrices_stddev_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "productPrices" */
export type ProductPrices_Stddev_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ProductPrices_Stddev_Pop_Fields = {
  __typename?: 'productPrices_stddev_pop_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "productPrices" */
export type ProductPrices_Stddev_Pop_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ProductPrices_Stddev_Samp_Fields = {
  __typename?: 'productPrices_stddev_samp_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "productPrices" */
export type ProductPrices_Stddev_Samp_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** Streaming cursor of the table "productPrices" */
export type ProductPrices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductPrices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductPrices_Stream_Cursor_Value_Input = {
  basePricePerDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  insuranceCostsPerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
  productId?: Maybe<Scalars['uuid']>;
  protectionCostsPerDay?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ProductPrices_Sum_Fields = {
  __typename?: 'productPrices_sum_fields';
  basePricePerDay?: Maybe<Scalars['Int']>;
  insuranceCostsPerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
  protectionCostsPerDay?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "productPrices" */
export type ProductPrices_Sum_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type ProductPrices_Var_Pop_Fields = {
  __typename?: 'productPrices_var_pop_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "productPrices" */
export type ProductPrices_Var_Pop_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ProductPrices_Var_Samp_Fields = {
  __typename?: 'productPrices_var_samp_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "productPrices" */
export type ProductPrices_Var_Samp_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ProductPrices_Variance_Fields = {
  __typename?: 'productPrices_variance_fields';
  basePricePerDay?: Maybe<Scalars['Float']>;
  insuranceCostsPerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  protectionCostsPerDay?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "productPrices" */
export type ProductPrices_Variance_Order_By = {
  basePricePerDay?: Maybe<Order_By>;
  insuranceCostsPerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
  protectionCostsPerDay?: Maybe<Order_By>;
};

/** columns and relationships of "products" */
export type Products = {
  __typename?: 'products';
  bikesAvailable?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  locationId: Scalars['uuid'];
  /** An array relationship */
  productPrices: Array<ProductPrices>;
  /** An aggregate relationship */
  productPrices_aggregate: ProductPrices_Aggregate;
  /** An object relationship */
  productType: ProductTypes;
  productTypeId: Scalars['uuid'];
  size: Scalars['String'];
  /** An array relationship */
  snapshotAvailabilities: Array<SnapshotAvailabilities>;
  /** An aggregate relationship */
  snapshotAvailabilities_aggregate: SnapshotAvailabilities_Aggregate;
  sortPosition?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "products" */
export type ProductsProductPricesArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSnapshotAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSnapshotAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};

/** aggregated selection of "products" */
export type Products_Aggregate = {
  __typename?: 'products_aggregate';
  aggregate?: Maybe<Products_Aggregate_Fields>;
  nodes: Array<Products>;
};

export type Products_Aggregate_Bool_Exp = {
  count?: Maybe<Products_Aggregate_Bool_Exp_Count>;
};

export type Products_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Products_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Products_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "products" */
export type Products_Aggregate_Fields = {
  __typename?: 'products_aggregate_fields';
  avg?: Maybe<Products_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Products_Max_Fields>;
  min?: Maybe<Products_Min_Fields>;
  stddev?: Maybe<Products_Stddev_Fields>;
  stddev_pop?: Maybe<Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Products_Stddev_Samp_Fields>;
  sum?: Maybe<Products_Sum_Fields>;
  var_pop?: Maybe<Products_Var_Pop_Fields>;
  var_samp?: Maybe<Products_Var_Samp_Fields>;
  variance?: Maybe<Products_Variance_Fields>;
};


/** aggregate fields of "products" */
export type Products_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Products_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "products" */
export type Products_Aggregate_Order_By = {
  avg?: Maybe<Products_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Products_Max_Order_By>;
  min?: Maybe<Products_Min_Order_By>;
  stddev?: Maybe<Products_Stddev_Order_By>;
  stddev_pop?: Maybe<Products_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Products_Stddev_Samp_Order_By>;
  sum?: Maybe<Products_Sum_Order_By>;
  var_pop?: Maybe<Products_Var_Pop_Order_By>;
  var_samp?: Maybe<Products_Var_Samp_Order_By>;
  variance?: Maybe<Products_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Products_Avg_Fields = {
  __typename?: 'products_avg_fields';
  bikesAvailable?: Maybe<Scalars['Float']>;
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "products" */
export type Products_Avg_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type Products_Bool_Exp = {
  _and?: Maybe<Array<Products_Bool_Exp>>;
  _not?: Maybe<Products_Bool_Exp>;
  _or?: Maybe<Array<Products_Bool_Exp>>;
  bikesAvailable?: Maybe<Int_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  productPrices?: Maybe<ProductPrices_Bool_Exp>;
  productPrices_aggregate?: Maybe<ProductPrices_Aggregate_Bool_Exp>;
  productType?: Maybe<ProductTypes_Bool_Exp>;
  productTypeId?: Maybe<Uuid_Comparison_Exp>;
  size?: Maybe<String_Comparison_Exp>;
  snapshotAvailabilities?: Maybe<SnapshotAvailabilities_Bool_Exp>;
  snapshotAvailabilities_aggregate?: Maybe<SnapshotAvailabilities_Aggregate_Bool_Exp>;
  sortPosition?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Products_Max_Fields = {
  __typename?: 'products_max_fields';
  bikesAvailable?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  productTypeId?: Maybe<Scalars['uuid']>;
  size?: Maybe<Scalars['String']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "products" */
export type Products_Max_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  productTypeId?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Products_Min_Fields = {
  __typename?: 'products_min_fields';
  bikesAvailable?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  productTypeId?: Maybe<Scalars['uuid']>;
  size?: Maybe<Scalars['String']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "products" */
export type Products_Min_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  productTypeId?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "products". */
export type Products_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  locationId?: Maybe<Order_By>;
  productPrices_aggregate?: Maybe<ProductPrices_Aggregate_Order_By>;
  productType?: Maybe<ProductTypes_Order_By>;
  productTypeId?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  snapshotAvailabilities_aggregate?: Maybe<SnapshotAvailabilities_Aggregate_Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** select columns of table "products" */
export enum Products_Select_Column {
  /** column name */
  BikesAvailable = 'bikesAvailable',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  ProductTypeId = 'productTypeId',
  /** column name */
  Size = 'size',
  /** column name */
  SortPosition = 'sortPosition'
}

/** aggregate stddev on columns */
export type Products_Stddev_Fields = {
  __typename?: 'products_stddev_fields';
  bikesAvailable?: Maybe<Scalars['Float']>;
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "products" */
export type Products_Stddev_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Products_Stddev_Pop_Fields = {
  __typename?: 'products_stddev_pop_fields';
  bikesAvailable?: Maybe<Scalars['Float']>;
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "products" */
export type Products_Stddev_Pop_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Products_Stddev_Samp_Fields = {
  __typename?: 'products_stddev_samp_fields';
  bikesAvailable?: Maybe<Scalars['Float']>;
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "products" */
export type Products_Stddev_Samp_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** Streaming cursor of the table "products" */
export type Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Products_Stream_Cursor_Value_Input = {
  bikesAvailable?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  productTypeId?: Maybe<Scalars['uuid']>;
  size?: Maybe<Scalars['String']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Products_Sum_Fields = {
  __typename?: 'products_sum_fields';
  bikesAvailable?: Maybe<Scalars['Int']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "products" */
export type Products_Sum_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Products_Var_Pop_Fields = {
  __typename?: 'products_var_pop_fields';
  bikesAvailable?: Maybe<Scalars['Float']>;
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "products" */
export type Products_Var_Pop_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Products_Var_Samp_Fields = {
  __typename?: 'products_var_samp_fields';
  bikesAvailable?: Maybe<Scalars['Float']>;
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "products" */
export type Products_Var_Samp_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Products_Variance_Fields = {
  __typename?: 'products_variance_fields';
  bikesAvailable?: Maybe<Scalars['Float']>;
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "products" */
export type Products_Variance_Order_By = {
  bikesAvailable?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** columns and relationships of "productTypeAvailabilities" */
export type ProductTypeAvailabilities = {
  __typename?: 'productTypeAvailabilities';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  sumAvailableBikes?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "productTypeAvailabilities" */
export type ProductTypeAvailabilitiesProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "productTypeAvailabilities" */
export type ProductTypeAvailabilitiesProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};

/** aggregated selection of "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Aggregate = {
  __typename?: 'productTypeAvailabilities_aggregate';
  aggregate?: Maybe<ProductTypeAvailabilities_Aggregate_Fields>;
  nodes: Array<ProductTypeAvailabilities>;
};

export type ProductTypeAvailabilities_Aggregate_Bool_Exp = {
  count?: Maybe<ProductTypeAvailabilities_Aggregate_Bool_Exp_Count>;
};

export type ProductTypeAvailabilities_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Aggregate_Fields = {
  __typename?: 'productTypeAvailabilities_aggregate_fields';
  avg?: Maybe<ProductTypeAvailabilities_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProductTypeAvailabilities_Max_Fields>;
  min?: Maybe<ProductTypeAvailabilities_Min_Fields>;
  stddev?: Maybe<ProductTypeAvailabilities_Stddev_Fields>;
  stddev_pop?: Maybe<ProductTypeAvailabilities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductTypeAvailabilities_Stddev_Samp_Fields>;
  sum?: Maybe<ProductTypeAvailabilities_Sum_Fields>;
  var_pop?: Maybe<ProductTypeAvailabilities_Var_Pop_Fields>;
  var_samp?: Maybe<ProductTypeAvailabilities_Var_Samp_Fields>;
  variance?: Maybe<ProductTypeAvailabilities_Variance_Fields>;
};


/** aggregate fields of "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Aggregate_Order_By = {
  avg?: Maybe<ProductTypeAvailabilities_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ProductTypeAvailabilities_Max_Order_By>;
  min?: Maybe<ProductTypeAvailabilities_Min_Order_By>;
  stddev?: Maybe<ProductTypeAvailabilities_Stddev_Order_By>;
  stddev_pop?: Maybe<ProductTypeAvailabilities_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ProductTypeAvailabilities_Stddev_Samp_Order_By>;
  sum?: Maybe<ProductTypeAvailabilities_Sum_Order_By>;
  var_pop?: Maybe<ProductTypeAvailabilities_Var_Pop_Order_By>;
  var_samp?: Maybe<ProductTypeAvailabilities_Var_Samp_Order_By>;
  variance?: Maybe<ProductTypeAvailabilities_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ProductTypeAvailabilities_Avg_Fields = {
  __typename?: 'productTypeAvailabilities_avg_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Avg_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "productTypeAvailabilities". All fields are combined with a logical 'AND'. */
export type ProductTypeAvailabilities_Bool_Exp = {
  _and?: Maybe<Array<ProductTypeAvailabilities_Bool_Exp>>;
  _not?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
  _or?: Maybe<Array<ProductTypeAvailabilities_Bool_Exp>>;
  date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  products?: Maybe<Products_Bool_Exp>;
  products_aggregate?: Maybe<Products_Aggregate_Bool_Exp>;
  sumAvailableBikes?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type ProductTypeAvailabilities_Max_Fields = {
  __typename?: 'productTypeAvailabilities_max_fields';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  sumAvailableBikes?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Max_Order_By = {
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  sumAvailableBikes?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ProductTypeAvailabilities_Min_Fields = {
  __typename?: 'productTypeAvailabilities_min_fields';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  sumAvailableBikes?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Min_Order_By = {
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  sumAvailableBikes?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "productTypeAvailabilities". */
export type ProductTypeAvailabilities_Order_By = {
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
  sumAvailableBikes?: Maybe<Order_By>;
};

/** select columns of table "productTypeAvailabilities" */
export enum ProductTypeAvailabilities_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  SumAvailableBikes = 'sumAvailableBikes'
}

/** aggregate stddev on columns */
export type ProductTypeAvailabilities_Stddev_Fields = {
  __typename?: 'productTypeAvailabilities_stddev_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Stddev_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ProductTypeAvailabilities_Stddev_Pop_Fields = {
  __typename?: 'productTypeAvailabilities_stddev_pop_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Stddev_Pop_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ProductTypeAvailabilities_Stddev_Samp_Fields = {
  __typename?: 'productTypeAvailabilities_stddev_samp_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Stddev_Samp_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** Streaming cursor of the table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductTypeAvailabilities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductTypeAvailabilities_Stream_Cursor_Value_Input = {
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  sumAvailableBikes?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type ProductTypeAvailabilities_Sum_Fields = {
  __typename?: 'productTypeAvailabilities_sum_fields';
  sumAvailableBikes?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Sum_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type ProductTypeAvailabilities_Var_Pop_Fields = {
  __typename?: 'productTypeAvailabilities_var_pop_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Var_Pop_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ProductTypeAvailabilities_Var_Samp_Fields = {
  __typename?: 'productTypeAvailabilities_var_samp_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Var_Samp_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ProductTypeAvailabilities_Variance_Fields = {
  __typename?: 'productTypeAvailabilities_variance_fields';
  sumAvailableBikes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "productTypeAvailabilities" */
export type ProductTypeAvailabilities_Variance_Order_By = {
  sumAvailableBikes?: Maybe<Order_By>;
};

/** columns and relationships of "productTypeModels" */
export type ProductTypeModels = {
  __typename?: 'productTypeModels';
  id: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  productTypes: Array<ProductTypes>;
  /** An aggregate relationship */
  productTypes_aggregate: ProductTypes_Aggregate;
};


/** columns and relationships of "productTypeModels" */
export type ProductTypeModelsProductTypesArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


/** columns and relationships of "productTypeModels" */
export type ProductTypeModelsProductTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};

/** aggregated selection of "productTypeModels" */
export type ProductTypeModels_Aggregate = {
  __typename?: 'productTypeModels_aggregate';
  aggregate?: Maybe<ProductTypeModels_Aggregate_Fields>;
  nodes: Array<ProductTypeModels>;
};

/** aggregate fields of "productTypeModels" */
export type ProductTypeModels_Aggregate_Fields = {
  __typename?: 'productTypeModels_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProductTypeModels_Max_Fields>;
  min?: Maybe<ProductTypeModels_Min_Fields>;
};


/** aggregate fields of "productTypeModels" */
export type ProductTypeModels_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductTypeModels_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "productTypeModels". All fields are combined with a logical 'AND'. */
export type ProductTypeModels_Bool_Exp = {
  _and?: Maybe<Array<ProductTypeModels_Bool_Exp>>;
  _not?: Maybe<ProductTypeModels_Bool_Exp>;
  _or?: Maybe<Array<ProductTypeModels_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  productTypes?: Maybe<ProductTypes_Bool_Exp>;
  productTypes_aggregate?: Maybe<ProductTypes_Aggregate_Bool_Exp>;
};

/** aggregate max on columns */
export type ProductTypeModels_Max_Fields = {
  __typename?: 'productTypeModels_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProductTypeModels_Min_Fields = {
  __typename?: 'productTypeModels_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "productTypeModels". */
export type ProductTypeModels_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  productTypes_aggregate?: Maybe<ProductTypes_Aggregate_Order_By>;
};

/** select columns of table "productTypeModels" */
export enum ProductTypeModels_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "productTypeModels" */
export type ProductTypeModels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductTypeModels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductTypeModels_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** columns and relationships of "productTypePrices" */
export type ProductTypePrices = {
  __typename?: 'productTypePrices';
  id?: Maybe<Scalars['uuid']>;
  minBasePricePerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "productTypePrices" */
export type ProductTypePrices_Aggregate = {
  __typename?: 'productTypePrices_aggregate';
  aggregate?: Maybe<ProductTypePrices_Aggregate_Fields>;
  nodes: Array<ProductTypePrices>;
};

export type ProductTypePrices_Aggregate_Bool_Exp = {
  count?: Maybe<ProductTypePrices_Aggregate_Bool_Exp_Count>;
};

export type ProductTypePrices_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ProductTypePrices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProductTypePrices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "productTypePrices" */
export type ProductTypePrices_Aggregate_Fields = {
  __typename?: 'productTypePrices_aggregate_fields';
  avg?: Maybe<ProductTypePrices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProductTypePrices_Max_Fields>;
  min?: Maybe<ProductTypePrices_Min_Fields>;
  stddev?: Maybe<ProductTypePrices_Stddev_Fields>;
  stddev_pop?: Maybe<ProductTypePrices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductTypePrices_Stddev_Samp_Fields>;
  sum?: Maybe<ProductTypePrices_Sum_Fields>;
  var_pop?: Maybe<ProductTypePrices_Var_Pop_Fields>;
  var_samp?: Maybe<ProductTypePrices_Var_Samp_Fields>;
  variance?: Maybe<ProductTypePrices_Variance_Fields>;
};


/** aggregate fields of "productTypePrices" */
export type ProductTypePrices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductTypePrices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "productTypePrices" */
export type ProductTypePrices_Aggregate_Order_By = {
  avg?: Maybe<ProductTypePrices_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ProductTypePrices_Max_Order_By>;
  min?: Maybe<ProductTypePrices_Min_Order_By>;
  stddev?: Maybe<ProductTypePrices_Stddev_Order_By>;
  stddev_pop?: Maybe<ProductTypePrices_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ProductTypePrices_Stddev_Samp_Order_By>;
  sum?: Maybe<ProductTypePrices_Sum_Order_By>;
  var_pop?: Maybe<ProductTypePrices_Var_Pop_Order_By>;
  var_samp?: Maybe<ProductTypePrices_Var_Samp_Order_By>;
  variance?: Maybe<ProductTypePrices_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ProductTypePrices_Avg_Fields = {
  __typename?: 'productTypePrices_avg_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "productTypePrices" */
export type ProductTypePrices_Avg_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "productTypePrices". All fields are combined with a logical 'AND'. */
export type ProductTypePrices_Bool_Exp = {
  _and?: Maybe<Array<ProductTypePrices_Bool_Exp>>;
  _not?: Maybe<ProductTypePrices_Bool_Exp>;
  _or?: Maybe<Array<ProductTypePrices_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  minBasePricePerDay?: Maybe<Int_Comparison_Exp>;
  minDuration?: Maybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type ProductTypePrices_Max_Fields = {
  __typename?: 'productTypePrices_max_fields';
  id?: Maybe<Scalars['uuid']>;
  minBasePricePerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "productTypePrices" */
export type ProductTypePrices_Max_Order_By = {
  id?: Maybe<Order_By>;
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ProductTypePrices_Min_Fields = {
  __typename?: 'productTypePrices_min_fields';
  id?: Maybe<Scalars['uuid']>;
  minBasePricePerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "productTypePrices" */
export type ProductTypePrices_Min_Order_By = {
  id?: Maybe<Order_By>;
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "productTypePrices". */
export type ProductTypePrices_Order_By = {
  id?: Maybe<Order_By>;
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** select columns of table "productTypePrices" */
export enum ProductTypePrices_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MinBasePricePerDay = 'minBasePricePerDay',
  /** column name */
  MinDuration = 'minDuration'
}

/** aggregate stddev on columns */
export type ProductTypePrices_Stddev_Fields = {
  __typename?: 'productTypePrices_stddev_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "productTypePrices" */
export type ProductTypePrices_Stddev_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ProductTypePrices_Stddev_Pop_Fields = {
  __typename?: 'productTypePrices_stddev_pop_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "productTypePrices" */
export type ProductTypePrices_Stddev_Pop_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ProductTypePrices_Stddev_Samp_Fields = {
  __typename?: 'productTypePrices_stddev_samp_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "productTypePrices" */
export type ProductTypePrices_Stddev_Samp_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** Streaming cursor of the table "productTypePrices" */
export type ProductTypePrices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductTypePrices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductTypePrices_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
  minBasePricePerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type ProductTypePrices_Sum_Fields = {
  __typename?: 'productTypePrices_sum_fields';
  minBasePricePerDay?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "productTypePrices" */
export type ProductTypePrices_Sum_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type ProductTypePrices_Var_Pop_Fields = {
  __typename?: 'productTypePrices_var_pop_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "productTypePrices" */
export type ProductTypePrices_Var_Pop_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ProductTypePrices_Var_Samp_Fields = {
  __typename?: 'productTypePrices_var_samp_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "productTypePrices" */
export type ProductTypePrices_Var_Samp_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ProductTypePrices_Variance_Fields = {
  __typename?: 'productTypePrices_variance_fields';
  minBasePricePerDay?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "productTypePrices" */
export type ProductTypePrices_Variance_Order_By = {
  minBasePricePerDay?: Maybe<Order_By>;
  minDuration?: Maybe<Order_By>;
};

/** columns and relationships of "productTypes" */
export type ProductTypes = {
  __typename?: 'productTypes';
  /** An array relationship */
  availabilities: Array<ProductTypeAvailabilities>;
  /** An aggregate relationship */
  availabilities_aggregate: ProductTypeAvailabilities_Aggregate;
  category: Scalars['String'];
  description: Scalars['String'];
  disabled?: Maybe<Scalars['Boolean']>;
  forceInsurance: Scalars['Boolean'];
  id: Scalars['uuid'];
  image: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  prices: Array<ProductTypePrices>;
  /** An aggregate relationship */
  prices_aggregate: ProductTypePrices_Aggregate;
  /** An object relationship */
  productTypeModel: ProductTypeModels;
  productTypeModelId: Scalars['String'];
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  sortPosition?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "productTypes" */
export type ProductTypesAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


/** columns and relationships of "productTypes" */
export type ProductTypesAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


/** columns and relationships of "productTypes" */
export type ProductTypesPricesArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


/** columns and relationships of "productTypes" */
export type ProductTypesPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


/** columns and relationships of "productTypes" */
export type ProductTypesProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "productTypes" */
export type ProductTypesProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};

/** aggregated selection of "productTypes" */
export type ProductTypes_Aggregate = {
  __typename?: 'productTypes_aggregate';
  aggregate?: Maybe<ProductTypes_Aggregate_Fields>;
  nodes: Array<ProductTypes>;
};

export type ProductTypes_Aggregate_Bool_Exp = {
  bool_and?: Maybe<ProductTypes_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<ProductTypes_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<ProductTypes_Aggregate_Bool_Exp_Count>;
};

export type ProductTypes_Aggregate_Bool_Exp_Bool_And = {
  arguments: ProductTypes_Select_Column_ProductTypes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProductTypes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ProductTypes_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ProductTypes_Select_Column_ProductTypes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProductTypes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ProductTypes_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<ProductTypes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProductTypes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "productTypes" */
export type ProductTypes_Aggregate_Fields = {
  __typename?: 'productTypes_aggregate_fields';
  avg?: Maybe<ProductTypes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProductTypes_Max_Fields>;
  min?: Maybe<ProductTypes_Min_Fields>;
  stddev?: Maybe<ProductTypes_Stddev_Fields>;
  stddev_pop?: Maybe<ProductTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductTypes_Stddev_Samp_Fields>;
  sum?: Maybe<ProductTypes_Sum_Fields>;
  var_pop?: Maybe<ProductTypes_Var_Pop_Fields>;
  var_samp?: Maybe<ProductTypes_Var_Samp_Fields>;
  variance?: Maybe<ProductTypes_Variance_Fields>;
};


/** aggregate fields of "productTypes" */
export type ProductTypes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProductTypes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "productTypes" */
export type ProductTypes_Aggregate_Order_By = {
  avg?: Maybe<ProductTypes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ProductTypes_Max_Order_By>;
  min?: Maybe<ProductTypes_Min_Order_By>;
  stddev?: Maybe<ProductTypes_Stddev_Order_By>;
  stddev_pop?: Maybe<ProductTypes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ProductTypes_Stddev_Samp_Order_By>;
  sum?: Maybe<ProductTypes_Sum_Order_By>;
  var_pop?: Maybe<ProductTypes_Var_Pop_Order_By>;
  var_samp?: Maybe<ProductTypes_Var_Samp_Order_By>;
  variance?: Maybe<ProductTypes_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ProductTypes_Avg_Fields = {
  __typename?: 'productTypes_avg_fields';
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "productTypes" */
export type ProductTypes_Avg_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "productTypes". All fields are combined with a logical 'AND'. */
export type ProductTypes_Bool_Exp = {
  _and?: Maybe<Array<ProductTypes_Bool_Exp>>;
  _not?: Maybe<ProductTypes_Bool_Exp>;
  _or?: Maybe<Array<ProductTypes_Bool_Exp>>;
  availabilities?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
  availabilities_aggregate?: Maybe<ProductTypeAvailabilities_Aggregate_Bool_Exp>;
  category?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  forceInsurance?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  prices?: Maybe<ProductTypePrices_Bool_Exp>;
  prices_aggregate?: Maybe<ProductTypePrices_Aggregate_Bool_Exp>;
  productTypeModel?: Maybe<ProductTypeModels_Bool_Exp>;
  productTypeModelId?: Maybe<String_Comparison_Exp>;
  products?: Maybe<Products_Bool_Exp>;
  products_aggregate?: Maybe<Products_Aggregate_Bool_Exp>;
  sortPosition?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type ProductTypes_Max_Fields = {
  __typename?: 'productTypes_max_fields';
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productTypeModelId?: Maybe<Scalars['String']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "productTypes" */
export type ProductTypes_Max_Order_By = {
  category?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  productTypeModelId?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ProductTypes_Min_Fields = {
  __typename?: 'productTypes_min_fields';
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productTypeModelId?: Maybe<Scalars['String']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "productTypes" */
export type ProductTypes_Min_Order_By = {
  category?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  productTypeModelId?: Maybe<Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "productTypes". */
export type ProductTypes_Order_By = {
  availabilities_aggregate?: Maybe<ProductTypeAvailabilities_Aggregate_Order_By>;
  category?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  forceInsurance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  prices_aggregate?: Maybe<ProductTypePrices_Aggregate_Order_By>;
  productTypeModel?: Maybe<ProductTypeModels_Order_By>;
  productTypeModelId?: Maybe<Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
  sortPosition?: Maybe<Order_By>;
};

/** select columns of table "productTypes" */
export enum ProductTypes_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Description = 'description',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  ForceInsurance = 'forceInsurance',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  ProductTypeModelId = 'productTypeModelId',
  /** column name */
  SortPosition = 'sortPosition'
}

/** select "productTypes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "productTypes" */
export enum ProductTypes_Select_Column_ProductTypes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  ForceInsurance = 'forceInsurance'
}

/** select "productTypes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "productTypes" */
export enum ProductTypes_Select_Column_ProductTypes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  ForceInsurance = 'forceInsurance'
}

/** aggregate stddev on columns */
export type ProductTypes_Stddev_Fields = {
  __typename?: 'productTypes_stddev_fields';
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "productTypes" */
export type ProductTypes_Stddev_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ProductTypes_Stddev_Pop_Fields = {
  __typename?: 'productTypes_stddev_pop_fields';
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "productTypes" */
export type ProductTypes_Stddev_Pop_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ProductTypes_Stddev_Samp_Fields = {
  __typename?: 'productTypes_stddev_samp_fields';
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "productTypes" */
export type ProductTypes_Stddev_Samp_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** Streaming cursor of the table "productTypes" */
export type ProductTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductTypes_Stream_Cursor_Value_Input = {
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  forceInsurance?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productTypeModelId?: Maybe<Scalars['String']>;
  sortPosition?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ProductTypes_Sum_Fields = {
  __typename?: 'productTypes_sum_fields';
  sortPosition?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "productTypes" */
export type ProductTypes_Sum_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type ProductTypes_Var_Pop_Fields = {
  __typename?: 'productTypes_var_pop_fields';
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "productTypes" */
export type ProductTypes_Var_Pop_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ProductTypes_Var_Samp_Fields = {
  __typename?: 'productTypes_var_samp_fields';
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "productTypes" */
export type ProductTypes_Var_Samp_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ProductTypes_Variance_Fields = {
  __typename?: 'productTypes_variance_fields';
  sortPosition?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "productTypes" */
export type ProductTypes_Variance_Order_By = {
  sortPosition?: Maybe<Order_By>;
};

/** columns and relationships of "publicSettings" */
export type PublicSettings = {
  __typename?: 'publicSettings';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "publicSettings". All fields are combined with a logical 'AND'. */
export type PublicSettings_Bool_Exp = {
  _and?: Maybe<Array<PublicSettings_Bool_Exp>>;
  _not?: Maybe<PublicSettings_Bool_Exp>;
  _or?: Maybe<Array<PublicSettings_Bool_Exp>>;
  key?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "publicSettings". */
export type PublicSettings_Order_By = {
  key?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** select columns of table "publicSettings" */
export enum PublicSettings_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "publicSettings" */
export type PublicSettings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PublicSettings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PublicSettings_Stream_Cursor_Value_Input = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "bookingRequest" */
  bookingRequest: Array<BookingRequest>;
  /** fetch data from the table: "bookingRequest" using primary key columns */
  bookingRequest_by_pk?: Maybe<BookingRequest>;
  /** An array relationship */
  closingDates: Array<ClosingDates>;
  /** fetch data from the table: "closingDates" using primary key columns */
  closingDates_by_pk?: Maybe<ClosingDates>;
  /** fetch data from the table: "locationMessages" */
  locationMessages: Array<LocationMessages>;
  /** fetch data from the table: "locationMessages" using primary key columns */
  locationMessages_by_pk?: Maybe<LocationMessages>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** An array relationship */
  productPrices: Array<ProductPrices>;
  /** An aggregate relationship */
  productPrices_aggregate: ProductPrices_Aggregate;
  /** fetch data from the table: "productPrices" using primary key columns */
  productPrices_by_pk?: Maybe<ProductPrices>;
  /** fetch data from the table: "productTypeAvailabilities" */
  productTypeAvailabilities: Array<ProductTypeAvailabilities>;
  /** fetch aggregated fields from the table: "productTypeAvailabilities" */
  productTypeAvailabilities_aggregate: ProductTypeAvailabilities_Aggregate;
  /** fetch data from the table: "productTypeModels" */
  productTypeModels: Array<ProductTypeModels>;
  /** fetch aggregated fields from the table: "productTypeModels" */
  productTypeModels_aggregate: ProductTypeModels_Aggregate;
  /** fetch data from the table: "productTypeModels" using primary key columns */
  productTypeModels_by_pk?: Maybe<ProductTypeModels>;
  /** fetch data from the table: "productTypePrices" */
  productTypePrices: Array<ProductTypePrices>;
  /** fetch aggregated fields from the table: "productTypePrices" */
  productTypePrices_aggregate: ProductTypePrices_Aggregate;
  /** An array relationship */
  productTypes: Array<ProductTypes>;
  /** An aggregate relationship */
  productTypes_aggregate: ProductTypes_Aggregate;
  /** fetch data from the table: "productTypes" using primary key columns */
  productTypes_by_pk?: Maybe<ProductTypes>;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table: "publicSettings" */
  publicSettings: Array<PublicSettings>;
  /** fetch data from the table: "publicSettings" using primary key columns */
  publicSettings_by_pk?: Maybe<PublicSettings>;
  /** An array relationship */
  seasons: Array<Seasons>;
  /** fetch data from the table: "seasons" using primary key columns */
  seasons_by_pk?: Maybe<Seasons>;
  /** An array relationship */
  snapshotAvailabilities: Array<SnapshotAvailabilities>;
  /** An aggregate relationship */
  snapshotAvailabilities_aggregate: SnapshotAvailabilities_Aggregate;
  /** fetch data from the table: "snapshotAvailabilities" using primary key columns */
  snapshotAvailabilities_by_pk?: Maybe<SnapshotAvailabilities>;
};


export type Query_RootBookingRequestArgs = {
  distinct_on?: Maybe<Array<BookingRequest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BookingRequest_Order_By>>;
  where?: Maybe<BookingRequest_Bool_Exp>;
};


export type Query_RootBookingRequest_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClosingDatesArgs = {
  distinct_on?: Maybe<Array<ClosingDates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClosingDates_Order_By>>;
  where?: Maybe<ClosingDates_Bool_Exp>;
};


export type Query_RootClosingDates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocationMessagesArgs = {
  distinct_on?: Maybe<Array<LocationMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationMessages_Order_By>>;
  where?: Maybe<LocationMessages_Bool_Exp>;
};


export type Query_RootLocationMessages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProductPricesArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type Query_RootProductPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type Query_RootProductPrices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProductTypeAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type Query_RootProductTypeAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type Query_RootProductTypeModelsArgs = {
  distinct_on?: Maybe<Array<ProductTypeModels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeModels_Order_By>>;
  where?: Maybe<ProductTypeModels_Bool_Exp>;
};


export type Query_RootProductTypeModels_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypeModels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeModels_Order_By>>;
  where?: Maybe<ProductTypeModels_Bool_Exp>;
};


export type Query_RootProductTypeModels_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootProductTypePricesArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type Query_RootProductTypePrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type Query_RootProductTypesArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


export type Query_RootProductTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


export type Query_RootProductTypes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Query_RootProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Query_RootProducts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPublicSettingsArgs = {
  distinct_on?: Maybe<Array<PublicSettings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PublicSettings_Order_By>>;
  where?: Maybe<PublicSettings_Bool_Exp>;
};


export type Query_RootPublicSettings_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootSeasonsArgs = {
  distinct_on?: Maybe<Array<Seasons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seasons_Order_By>>;
  where?: Maybe<Seasons_Bool_Exp>;
};


export type Query_RootSeasons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSnapshotAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


export type Query_RootSnapshotAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


export type Query_RootSnapshotAvailabilities_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "seasons" */
export type Seasons = {
  __typename?: 'seasons';
  created_at: Scalars['timestamptz'];
  endDate: Scalars['date'];
  id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  locationId: Scalars['uuid'];
  name: Scalars['String'];
  startDate: Scalars['date'];
  updated_at: Scalars['timestamptz'];
};

/** order by aggregate values of table "seasons" */
export type Seasons_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Seasons_Max_Order_By>;
  min?: Maybe<Seasons_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "seasons". All fields are combined with a logical 'AND'. */
export type Seasons_Bool_Exp = {
  _and?: Maybe<Array<Seasons_Bool_Exp>>;
  _not?: Maybe<Seasons_Bool_Exp>;
  _or?: Maybe<Array<Seasons_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  endDate?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  startDate?: Maybe<Date_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "seasons" */
export type Seasons_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "seasons" */
export type Seasons_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "seasons". */
export type Seasons_Order_By = {
  created_at?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  locationId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "seasons" */
export enum Seasons_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Name = 'name',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "seasons" */
export type Seasons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Seasons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Seasons_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "snapshotAvailabilities" */
export type SnapshotAvailabilities = {
  __typename?: 'snapshotAvailabilities';
  availableBikes: Scalars['Int'];
  date: Scalars['date'];
  id: Scalars['uuid'];
  /** An object relationship */
  product: Products;
  productId: Scalars['uuid'];
};

/** aggregated selection of "snapshotAvailabilities" */
export type SnapshotAvailabilities_Aggregate = {
  __typename?: 'snapshotAvailabilities_aggregate';
  aggregate?: Maybe<SnapshotAvailabilities_Aggregate_Fields>;
  nodes: Array<SnapshotAvailabilities>;
};

export type SnapshotAvailabilities_Aggregate_Bool_Exp = {
  count?: Maybe<SnapshotAvailabilities_Aggregate_Bool_Exp_Count>;
};

export type SnapshotAvailabilities_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<SnapshotAvailabilities_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "snapshotAvailabilities" */
export type SnapshotAvailabilities_Aggregate_Fields = {
  __typename?: 'snapshotAvailabilities_aggregate_fields';
  avg?: Maybe<SnapshotAvailabilities_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SnapshotAvailabilities_Max_Fields>;
  min?: Maybe<SnapshotAvailabilities_Min_Fields>;
  stddev?: Maybe<SnapshotAvailabilities_Stddev_Fields>;
  stddev_pop?: Maybe<SnapshotAvailabilities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SnapshotAvailabilities_Stddev_Samp_Fields>;
  sum?: Maybe<SnapshotAvailabilities_Sum_Fields>;
  var_pop?: Maybe<SnapshotAvailabilities_Var_Pop_Fields>;
  var_samp?: Maybe<SnapshotAvailabilities_Var_Samp_Fields>;
  variance?: Maybe<SnapshotAvailabilities_Variance_Fields>;
};


/** aggregate fields of "snapshotAvailabilities" */
export type SnapshotAvailabilities_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Aggregate_Order_By = {
  avg?: Maybe<SnapshotAvailabilities_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SnapshotAvailabilities_Max_Order_By>;
  min?: Maybe<SnapshotAvailabilities_Min_Order_By>;
  stddev?: Maybe<SnapshotAvailabilities_Stddev_Order_By>;
  stddev_pop?: Maybe<SnapshotAvailabilities_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SnapshotAvailabilities_Stddev_Samp_Order_By>;
  sum?: Maybe<SnapshotAvailabilities_Sum_Order_By>;
  var_pop?: Maybe<SnapshotAvailabilities_Var_Pop_Order_By>;
  var_samp?: Maybe<SnapshotAvailabilities_Var_Samp_Order_By>;
  variance?: Maybe<SnapshotAvailabilities_Variance_Order_By>;
};

/** aggregate avg on columns */
export type SnapshotAvailabilities_Avg_Fields = {
  __typename?: 'snapshotAvailabilities_avg_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Avg_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "snapshotAvailabilities". All fields are combined with a logical 'AND'. */
export type SnapshotAvailabilities_Bool_Exp = {
  _and?: Maybe<Array<SnapshotAvailabilities_Bool_Exp>>;
  _not?: Maybe<SnapshotAvailabilities_Bool_Exp>;
  _or?: Maybe<Array<SnapshotAvailabilities_Bool_Exp>>;
  availableBikes?: Maybe<Int_Comparison_Exp>;
  date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  product?: Maybe<Products_Bool_Exp>;
  productId?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type SnapshotAvailabilities_Max_Fields = {
  __typename?: 'snapshotAvailabilities_max_fields';
  availableBikes?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Max_Order_By = {
  availableBikes?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SnapshotAvailabilities_Min_Fields = {
  __typename?: 'snapshotAvailabilities_min_fields';
  availableBikes?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Min_Order_By = {
  availableBikes?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  productId?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "snapshotAvailabilities". */
export type SnapshotAvailabilities_Order_By = {
  availableBikes?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product?: Maybe<Products_Order_By>;
  productId?: Maybe<Order_By>;
};

/** select columns of table "snapshotAvailabilities" */
export enum SnapshotAvailabilities_Select_Column {
  /** column name */
  AvailableBikes = 'availableBikes',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId'
}

/** aggregate stddev on columns */
export type SnapshotAvailabilities_Stddev_Fields = {
  __typename?: 'snapshotAvailabilities_stddev_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Stddev_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SnapshotAvailabilities_Stddev_Pop_Fields = {
  __typename?: 'snapshotAvailabilities_stddev_pop_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Stddev_Pop_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SnapshotAvailabilities_Stddev_Samp_Fields = {
  __typename?: 'snapshotAvailabilities_stddev_samp_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Stddev_Samp_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** Streaming cursor of the table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SnapshotAvailabilities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SnapshotAvailabilities_Stream_Cursor_Value_Input = {
  availableBikes?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type SnapshotAvailabilities_Sum_Fields = {
  __typename?: 'snapshotAvailabilities_sum_fields';
  availableBikes?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Sum_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type SnapshotAvailabilities_Var_Pop_Fields = {
  __typename?: 'snapshotAvailabilities_var_pop_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Var_Pop_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SnapshotAvailabilities_Var_Samp_Fields = {
  __typename?: 'snapshotAvailabilities_var_samp_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Var_Samp_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SnapshotAvailabilities_Variance_Fields = {
  __typename?: 'snapshotAvailabilities_variance_fields';
  availableBikes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "snapshotAvailabilities" */
export type SnapshotAvailabilities_Variance_Order_By = {
  availableBikes?: Maybe<Order_By>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "bookingRequest" */
  bookingRequest: Array<BookingRequest>;
  /** fetch data from the table: "bookingRequest" using primary key columns */
  bookingRequest_by_pk?: Maybe<BookingRequest>;
  /** fetch data from the table in a streaming manner: "bookingRequest" */
  bookingRequest_stream: Array<BookingRequest>;
  /** An array relationship */
  closingDates: Array<ClosingDates>;
  /** fetch data from the table: "closingDates" using primary key columns */
  closingDates_by_pk?: Maybe<ClosingDates>;
  /** fetch data from the table in a streaming manner: "closingDates" */
  closingDates_stream: Array<ClosingDates>;
  /** fetch data from the table: "locationMessages" */
  locationMessages: Array<LocationMessages>;
  /** fetch data from the table: "locationMessages" using primary key columns */
  locationMessages_by_pk?: Maybe<LocationMessages>;
  /** fetch data from the table in a streaming manner: "locationMessages" */
  locationMessages_stream: Array<LocationMessages>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table in a streaming manner: "locations" */
  locations_stream: Array<Locations>;
  /** An array relationship */
  productPrices: Array<ProductPrices>;
  /** An aggregate relationship */
  productPrices_aggregate: ProductPrices_Aggregate;
  /** fetch data from the table: "productPrices" using primary key columns */
  productPrices_by_pk?: Maybe<ProductPrices>;
  /** fetch data from the table in a streaming manner: "productPrices" */
  productPrices_stream: Array<ProductPrices>;
  /** fetch data from the table: "productTypeAvailabilities" */
  productTypeAvailabilities: Array<ProductTypeAvailabilities>;
  /** fetch aggregated fields from the table: "productTypeAvailabilities" */
  productTypeAvailabilities_aggregate: ProductTypeAvailabilities_Aggregate;
  /** fetch data from the table in a streaming manner: "productTypeAvailabilities" */
  productTypeAvailabilities_stream: Array<ProductTypeAvailabilities>;
  /** fetch data from the table: "productTypeModels" */
  productTypeModels: Array<ProductTypeModels>;
  /** fetch aggregated fields from the table: "productTypeModels" */
  productTypeModels_aggregate: ProductTypeModels_Aggregate;
  /** fetch data from the table: "productTypeModels" using primary key columns */
  productTypeModels_by_pk?: Maybe<ProductTypeModels>;
  /** fetch data from the table in a streaming manner: "productTypeModels" */
  productTypeModels_stream: Array<ProductTypeModels>;
  /** fetch data from the table: "productTypePrices" */
  productTypePrices: Array<ProductTypePrices>;
  /** fetch aggregated fields from the table: "productTypePrices" */
  productTypePrices_aggregate: ProductTypePrices_Aggregate;
  /** fetch data from the table in a streaming manner: "productTypePrices" */
  productTypePrices_stream: Array<ProductTypePrices>;
  /** An array relationship */
  productTypes: Array<ProductTypes>;
  /** An aggregate relationship */
  productTypes_aggregate: ProductTypes_Aggregate;
  /** fetch data from the table: "productTypes" using primary key columns */
  productTypes_by_pk?: Maybe<ProductTypes>;
  /** fetch data from the table in a streaming manner: "productTypes" */
  productTypes_stream: Array<ProductTypes>;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table in a streaming manner: "products" */
  products_stream: Array<Products>;
  /** fetch data from the table: "publicSettings" */
  publicSettings: Array<PublicSettings>;
  /** fetch data from the table: "publicSettings" using primary key columns */
  publicSettings_by_pk?: Maybe<PublicSettings>;
  /** fetch data from the table in a streaming manner: "publicSettings" */
  publicSettings_stream: Array<PublicSettings>;
  /** An array relationship */
  seasons: Array<Seasons>;
  /** fetch data from the table: "seasons" using primary key columns */
  seasons_by_pk?: Maybe<Seasons>;
  /** fetch data from the table in a streaming manner: "seasons" */
  seasons_stream: Array<Seasons>;
  /** An array relationship */
  snapshotAvailabilities: Array<SnapshotAvailabilities>;
  /** An aggregate relationship */
  snapshotAvailabilities_aggregate: SnapshotAvailabilities_Aggregate;
  /** fetch data from the table: "snapshotAvailabilities" using primary key columns */
  snapshotAvailabilities_by_pk?: Maybe<SnapshotAvailabilities>;
  /** fetch data from the table in a streaming manner: "snapshotAvailabilities" */
  snapshotAvailabilities_stream: Array<SnapshotAvailabilities>;
};


export type Subscription_RootBookingRequestArgs = {
  distinct_on?: Maybe<Array<BookingRequest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BookingRequest_Order_By>>;
  where?: Maybe<BookingRequest_Bool_Exp>;
};


export type Subscription_RootBookingRequest_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBookingRequest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<BookingRequest_Stream_Cursor_Input>>;
  where?: Maybe<BookingRequest_Bool_Exp>;
};


export type Subscription_RootClosingDatesArgs = {
  distinct_on?: Maybe<Array<ClosingDates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClosingDates_Order_By>>;
  where?: Maybe<ClosingDates_Bool_Exp>;
};


export type Subscription_RootClosingDates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClosingDates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ClosingDates_Stream_Cursor_Input>>;
  where?: Maybe<ClosingDates_Bool_Exp>;
};


export type Subscription_RootLocationMessagesArgs = {
  distinct_on?: Maybe<Array<LocationMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationMessages_Order_By>>;
  where?: Maybe<LocationMessages_Bool_Exp>;
};


export type Subscription_RootLocationMessages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocationMessages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<LocationMessages_Stream_Cursor_Input>>;
  where?: Maybe<LocationMessages_Bool_Exp>;
};


export type Subscription_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Locations_Stream_Cursor_Input>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootProductPricesArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type Subscription_RootProductPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductPrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductPrices_Order_By>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type Subscription_RootProductPrices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProductPrices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ProductPrices_Stream_Cursor_Input>>;
  where?: Maybe<ProductPrices_Bool_Exp>;
};


export type Subscription_RootProductTypeAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type Subscription_RootProductTypeAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypeAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeAvailabilities_Order_By>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type Subscription_RootProductTypeAvailabilities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ProductTypeAvailabilities_Stream_Cursor_Input>>;
  where?: Maybe<ProductTypeAvailabilities_Bool_Exp>;
};


export type Subscription_RootProductTypeModelsArgs = {
  distinct_on?: Maybe<Array<ProductTypeModels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeModels_Order_By>>;
  where?: Maybe<ProductTypeModels_Bool_Exp>;
};


export type Subscription_RootProductTypeModels_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypeModels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypeModels_Order_By>>;
  where?: Maybe<ProductTypeModels_Bool_Exp>;
};


export type Subscription_RootProductTypeModels_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootProductTypeModels_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ProductTypeModels_Stream_Cursor_Input>>;
  where?: Maybe<ProductTypeModels_Bool_Exp>;
};


export type Subscription_RootProductTypePricesArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type Subscription_RootProductTypePrices_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypePrices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypePrices_Order_By>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type Subscription_RootProductTypePrices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ProductTypePrices_Stream_Cursor_Input>>;
  where?: Maybe<ProductTypePrices_Bool_Exp>;
};


export type Subscription_RootProductTypesArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


export type Subscription_RootProductTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<ProductTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductTypes_Order_By>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


export type Subscription_RootProductTypes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProductTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ProductTypes_Stream_Cursor_Input>>;
  where?: Maybe<ProductTypes_Bool_Exp>;
};


export type Subscription_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProducts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Products_Stream_Cursor_Input>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootPublicSettingsArgs = {
  distinct_on?: Maybe<Array<PublicSettings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PublicSettings_Order_By>>;
  where?: Maybe<PublicSettings_Bool_Exp>;
};


export type Subscription_RootPublicSettings_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootPublicSettings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<PublicSettings_Stream_Cursor_Input>>;
  where?: Maybe<PublicSettings_Bool_Exp>;
};


export type Subscription_RootSeasonsArgs = {
  distinct_on?: Maybe<Array<Seasons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seasons_Order_By>>;
  where?: Maybe<Seasons_Bool_Exp>;
};


export type Subscription_RootSeasons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSeasons_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Seasons_Stream_Cursor_Input>>;
  where?: Maybe<Seasons_Bool_Exp>;
};


export type Subscription_RootSnapshotAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


export type Subscription_RootSnapshotAvailabilities_AggregateArgs = {
  distinct_on?: Maybe<Array<SnapshotAvailabilities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SnapshotAvailabilities_Order_By>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


export type Subscription_RootSnapshotAvailabilities_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSnapshotAvailabilities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<SnapshotAvailabilities_Stream_Cursor_Input>>;
  where?: Maybe<SnapshotAvailabilities_Bool_Exp>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type VerifyOrderResult = {
  __typename?: 'VerifyOrderResult';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type VerifyOrderMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type VerifyOrderMutation = (
  { __typename?: 'mutation_root' }
  & { verifyOrder?: Maybe<(
    { __typename?: 'VerifyOrderResult' }
    & Pick<VerifyOrderResult, 'message' | 'success'>
  )> }
);

export type SendBookingRequestMutationVariables = Exact<{
  objects: Array<BookingRequest_Insert_Input> | BookingRequest_Insert_Input;
}>;


export type SendBookingRequestMutation = (
  { __typename?: 'mutation_root' }
  & { insert_bookingRequest?: Maybe<(
    { __typename?: 'bookingRequest_mutation_response' }
    & { returning: Array<(
      { __typename?: 'bookingRequest' }
      & Pick<BookingRequest, 'id'>
    )> }
  )> }
);

export type GetClosingDatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClosingDatesQuery = (
  { __typename?: 'query_root' }
  & { closingDates: Array<(
    { __typename?: 'closingDates' }
    & Pick<ClosingDates, 'closedDate' | 'description'>
  )> }
);

export type GetLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsQuery = (
  { __typename?: 'query_root' }
  & { locations: Array<(
    { __typename?: 'locations' }
    & Pick<Locations, 'id' | 'name' | 'description' | 'stealthMode'>
    & { messages: Array<(
      { __typename?: 'locationMessages' }
      & Pick<LocationMessages, 'title' | 'message' | 'startDate' | 'endDate'>
      & { location: (
        { __typename?: 'locations' }
        & Pick<Locations, 'name' | 'description'>
      ) }
    )> }
  )> }
);

export type GetProductTypeQueryVariables = Exact<{
  startDate: Scalars['date'];
  endDate: Scalars['date'];
  duration: Scalars['numeric'];
  productTypeId: Scalars['uuid'];
  locationId: Scalars['uuid'];
}>;


export type GetProductTypeQuery = (
  { __typename?: 'query_root' }
  & { productTypes_by_pk?: Maybe<(
    { __typename?: 'productTypes' }
    & Pick<ProductTypes, 'id' | 'name' | 'description' | 'productTypeModelId' | 'image' | 'category' | 'forceInsurance'>
    & { products: Array<(
      { __typename?: 'products' }
      & Pick<Products, 'id' | 'size' | 'description'>
      & { productPrices_aggregate: (
        { __typename?: 'productPrices_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'productPrices_aggregate_fields' }
          & { max?: Maybe<(
            { __typename?: 'productPrices_max_fields' }
            & Pick<ProductPrices_Max_Fields, 'basePricePerDay' | 'insuranceCostsPerDay' | 'protectionCostsPerDay'>
          )> }
        )> }
      ), snapshotAvailabilities_aggregate: (
        { __typename?: 'snapshotAvailabilities_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'snapshotAvailabilities_aggregate_fields' }
          & { min?: Maybe<(
            { __typename?: 'snapshotAvailabilities_min_fields' }
            & Pick<SnapshotAvailabilities_Min_Fields, 'availableBikes'>
          )> }
        )> }
      ) }
    )> }
  )> }
);

export type GetPoductTypesOverviewQueryVariables = Exact<{
  startDate: Scalars['date'];
  endDate: Scalars['date'];
  duration: Scalars['numeric'];
  locationId: Scalars['uuid'];
}>;


export type GetPoductTypesOverviewQuery = (
  { __typename?: 'query_root' }
  & { productTypes: Array<(
    { __typename?: 'productTypes' }
    & Pick<ProductTypes, 'id' | 'name' | 'category' | 'description' | 'image'>
    & { availabilities_aggregate: (
      { __typename?: 'productTypeAvailabilities_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'productTypeAvailabilities_aggregate_fields' }
        & { min?: Maybe<(
          { __typename?: 'productTypeAvailabilities_min_fields' }
          & Pick<ProductTypeAvailabilities_Min_Fields, 'sumAvailableBikes'>
        )> }
      )> }
    ), prices_aggregate: (
      { __typename?: 'productTypePrices_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'productTypePrices_aggregate_fields' }
        & { min?: Maybe<(
          { __typename?: 'productTypePrices_min_fields' }
          & Pick<ProductTypePrices_Min_Fields, 'minBasePricePerDay'>
        )>, max?: Maybe<(
          { __typename?: 'productTypePrices_max_fields' }
          & Pick<ProductTypePrices_Max_Fields, 'minBasePricePerDay'>
        )> }
      )> }
    ) }
  )> }
);

export type GetPublicSettingQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type GetPublicSettingQuery = (
  { __typename?: 'query_root' }
  & { publicSettings_by_pk?: Maybe<(
    { __typename?: 'publicSettings' }
    & Pick<PublicSettings, 'value'>
  )> }
);

export type GetSeasonByDateQueryVariables = Exact<{
  date: Scalars['date'];
}>;


export type GetSeasonByDateQuery = (
  { __typename?: 'query_root' }
  & { seasons: Array<(
    { __typename?: 'seasons' }
    & Pick<Seasons, 'id' | 'name' | 'locationId' | 'startDate' | 'endDate' | 'created_at' | 'updated_at'>
  )> }
);

export type GetSeasonByLocationQueryVariables = Exact<{
  locationId: Scalars['uuid'];
}>;


export type GetSeasonByLocationQuery = (
  { __typename?: 'query_root' }
  & { seasons: Array<(
    { __typename?: 'seasons' }
    & Pick<Seasons, 'id' | 'name' | 'locationId' | 'startDate' | 'endDate' | 'created_at' | 'updated_at'>
  )>, closingDates: Array<(
    { __typename?: 'closingDates' }
    & Pick<ClosingDates, 'id' | 'locationId' | 'closedDate' | 'description'>
  )> }
);

export type BookingRequestSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type BookingRequestSubscription = (
  { __typename?: 'subscription_root' }
  & { bookingRequest_by_pk?: Maybe<(
    { __typename?: 'bookingRequest' }
    & Pick<BookingRequest, 'success'>
  )> }
);


export const VerifyOrderDocument = gql`
    mutation VerifyOrder($id: String!) {
  verifyOrder(id: $id) {
    message
    success
  }
}
    `;
export type VerifyOrderMutationFn = Apollo.MutationFunction<VerifyOrderMutation, VerifyOrderMutationVariables>;

/**
 * __useVerifyOrderMutation__
 *
 * To run a mutation, you first call `useVerifyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOrderMutation, { data, loading, error }] = useVerifyOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVerifyOrderMutation(baseOptions?: Apollo.MutationHookOptions<VerifyOrderMutation, VerifyOrderMutationVariables>) {
        return Apollo.useMutation<VerifyOrderMutation, VerifyOrderMutationVariables>(VerifyOrderDocument, baseOptions);
      }
export type VerifyOrderMutationHookResult = ReturnType<typeof useVerifyOrderMutation>;
export type VerifyOrderMutationResult = Apollo.MutationResult<VerifyOrderMutation>;
export type VerifyOrderMutationOptions = Apollo.BaseMutationOptions<VerifyOrderMutation, VerifyOrderMutationVariables>;
export const SendBookingRequestDocument = gql`
    mutation sendBookingRequest($objects: [bookingRequest_insert_input!]!) {
  insert_bookingRequest(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type SendBookingRequestMutationFn = Apollo.MutationFunction<SendBookingRequestMutation, SendBookingRequestMutationVariables>;

/**
 * __useSendBookingRequestMutation__
 *
 * To run a mutation, you first call `useSendBookingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBookingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBookingRequestMutation, { data, loading, error }] = useSendBookingRequestMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useSendBookingRequestMutation(baseOptions?: Apollo.MutationHookOptions<SendBookingRequestMutation, SendBookingRequestMutationVariables>) {
        return Apollo.useMutation<SendBookingRequestMutation, SendBookingRequestMutationVariables>(SendBookingRequestDocument, baseOptions);
      }
export type SendBookingRequestMutationHookResult = ReturnType<typeof useSendBookingRequestMutation>;
export type SendBookingRequestMutationResult = Apollo.MutationResult<SendBookingRequestMutation>;
export type SendBookingRequestMutationOptions = Apollo.BaseMutationOptions<SendBookingRequestMutation, SendBookingRequestMutationVariables>;
export const GetClosingDatesDocument = gql`
    query getClosingDates {
  closingDates {
    closedDate
    description
  }
}
    `;

/**
 * __useGetClosingDatesQuery__
 *
 * To run a query within a React component, call `useGetClosingDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClosingDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosingDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClosingDatesQuery(baseOptions?: Apollo.QueryHookOptions<GetClosingDatesQuery, GetClosingDatesQueryVariables>) {
        return Apollo.useQuery<GetClosingDatesQuery, GetClosingDatesQueryVariables>(GetClosingDatesDocument, baseOptions);
      }
export function useGetClosingDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClosingDatesQuery, GetClosingDatesQueryVariables>) {
          return Apollo.useLazyQuery<GetClosingDatesQuery, GetClosingDatesQueryVariables>(GetClosingDatesDocument, baseOptions);
        }
export type GetClosingDatesQueryHookResult = ReturnType<typeof useGetClosingDatesQuery>;
export type GetClosingDatesLazyQueryHookResult = ReturnType<typeof useGetClosingDatesLazyQuery>;
export type GetClosingDatesQueryResult = Apollo.QueryResult<GetClosingDatesQuery, GetClosingDatesQueryVariables>;
export const GetLocationsDocument = gql`
    query getLocations {
  locations {
    id
    name
    description
    stealthMode
    messages {
      title
      message
      startDate
      endDate
      location {
        name
        description
      }
    }
  }
}
    `;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, baseOptions);
      }
export function useGetLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, baseOptions);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetProductTypeDocument = gql`
    query getProductType($startDate: date!, $endDate: date!, $duration: numeric!, $productTypeId: uuid!, $locationId: uuid!) {
  productTypes_by_pk(id: $productTypeId) {
    id
    name
    description
    productTypeModelId
    image
    category
    forceInsurance
    products(where: {_and: [{productPrices: {basePricePerDay: {_gte: 0}}}, {location: {id: {_eq: $locationId}}}]}, order_by: {sortPosition: asc_nulls_last}) {
      id
      size
      description
      productPrices_aggregate(where: {_and: {minDuration: {_lte: $duration}, basePricePerDay: {_gt: 0}}}, order_by: [{minDuration: desc}], limit: 1) {
        aggregate {
          max {
            basePricePerDay
            insuranceCostsPerDay
            protectionCostsPerDay
          }
        }
      }
      snapshotAvailabilities_aggregate(where: {date: {_gte: $startDate, _lte: $endDate}}) {
        aggregate {
          min {
            availableBikes
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetProductTypeQuery__
 *
 * To run a query within a React component, call `useGetProductTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductTypeQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      duration: // value for 'duration'
 *      productTypeId: // value for 'productTypeId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetProductTypeQuery(baseOptions: Apollo.QueryHookOptions<GetProductTypeQuery, GetProductTypeQueryVariables>) {
        return Apollo.useQuery<GetProductTypeQuery, GetProductTypeQueryVariables>(GetProductTypeDocument, baseOptions);
      }
export function useGetProductTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductTypeQuery, GetProductTypeQueryVariables>) {
          return Apollo.useLazyQuery<GetProductTypeQuery, GetProductTypeQueryVariables>(GetProductTypeDocument, baseOptions);
        }
export type GetProductTypeQueryHookResult = ReturnType<typeof useGetProductTypeQuery>;
export type GetProductTypeLazyQueryHookResult = ReturnType<typeof useGetProductTypeLazyQuery>;
export type GetProductTypeQueryResult = Apollo.QueryResult<GetProductTypeQuery, GetProductTypeQueryVariables>;
export const GetPoductTypesOverviewDocument = gql`
    query getPoductTypesOverview($startDate: date!, $endDate: date!, $duration: numeric!, $locationId: uuid!) {
  productTypes(order_by: {sortPosition: asc_nulls_last}) {
    id
    name
    category
    description
    image
    availabilities_aggregate(where: {_and: [{date: {_gte: $startDate, _lte: $endDate}}, {locationId: {_eq: $locationId}}]}) {
      aggregate {
        min {
          sumAvailableBikes
        }
      }
    }
    prices_aggregate(where: {minDuration: {_lte: $duration}}, order_by: [{minDuration: desc}]) {
      aggregate {
        min {
          minBasePricePerDay
        }
        max {
          minBasePricePerDay
        }
      }
    }
  }
}
    `;

/**
 * __useGetPoductTypesOverviewQuery__
 *
 * To run a query within a React component, call `useGetPoductTypesOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoductTypesOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoductTypesOverviewQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      duration: // value for 'duration'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetPoductTypesOverviewQuery(baseOptions: Apollo.QueryHookOptions<GetPoductTypesOverviewQuery, GetPoductTypesOverviewQueryVariables>) {
        return Apollo.useQuery<GetPoductTypesOverviewQuery, GetPoductTypesOverviewQueryVariables>(GetPoductTypesOverviewDocument, baseOptions);
      }
export function useGetPoductTypesOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPoductTypesOverviewQuery, GetPoductTypesOverviewQueryVariables>) {
          return Apollo.useLazyQuery<GetPoductTypesOverviewQuery, GetPoductTypesOverviewQueryVariables>(GetPoductTypesOverviewDocument, baseOptions);
        }
export type GetPoductTypesOverviewQueryHookResult = ReturnType<typeof useGetPoductTypesOverviewQuery>;
export type GetPoductTypesOverviewLazyQueryHookResult = ReturnType<typeof useGetPoductTypesOverviewLazyQuery>;
export type GetPoductTypesOverviewQueryResult = Apollo.QueryResult<GetPoductTypesOverviewQuery, GetPoductTypesOverviewQueryVariables>;
export const GetPublicSettingDocument = gql`
    query getPublicSetting($key: String!) {
  publicSettings_by_pk(key: $key) {
    value
  }
}
    `;

/**
 * __useGetPublicSettingQuery__
 *
 * To run a query within a React component, call `useGetPublicSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicSettingQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetPublicSettingQuery(baseOptions: Apollo.QueryHookOptions<GetPublicSettingQuery, GetPublicSettingQueryVariables>) {
        return Apollo.useQuery<GetPublicSettingQuery, GetPublicSettingQueryVariables>(GetPublicSettingDocument, baseOptions);
      }
export function useGetPublicSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicSettingQuery, GetPublicSettingQueryVariables>) {
          return Apollo.useLazyQuery<GetPublicSettingQuery, GetPublicSettingQueryVariables>(GetPublicSettingDocument, baseOptions);
        }
export type GetPublicSettingQueryHookResult = ReturnType<typeof useGetPublicSettingQuery>;
export type GetPublicSettingLazyQueryHookResult = ReturnType<typeof useGetPublicSettingLazyQuery>;
export type GetPublicSettingQueryResult = Apollo.QueryResult<GetPublicSettingQuery, GetPublicSettingQueryVariables>;
export const GetSeasonByDateDocument = gql`
    query GetSeasonByDate($date: date!) {
  seasons(where: {_and: [{startDate: {_lte: $date}}, {endDate: {_gte: $date}}]}, order_by: [{startDate: asc}], limit: 1) {
    id
    name
    locationId
    startDate
    endDate
    created_at
    updated_at
  }
}
    `;

/**
 * __useGetSeasonByDateQuery__
 *
 * To run a query within a React component, call `useGetSeasonByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetSeasonByDateQuery(baseOptions: Apollo.QueryHookOptions<GetSeasonByDateQuery, GetSeasonByDateQueryVariables>) {
        return Apollo.useQuery<GetSeasonByDateQuery, GetSeasonByDateQueryVariables>(GetSeasonByDateDocument, baseOptions);
      }
export function useGetSeasonByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeasonByDateQuery, GetSeasonByDateQueryVariables>) {
          return Apollo.useLazyQuery<GetSeasonByDateQuery, GetSeasonByDateQueryVariables>(GetSeasonByDateDocument, baseOptions);
        }
export type GetSeasonByDateQueryHookResult = ReturnType<typeof useGetSeasonByDateQuery>;
export type GetSeasonByDateLazyQueryHookResult = ReturnType<typeof useGetSeasonByDateLazyQuery>;
export type GetSeasonByDateQueryResult = Apollo.QueryResult<GetSeasonByDateQuery, GetSeasonByDateQueryVariables>;
export const GetSeasonByLocationDocument = gql`
    query GetSeasonByLocation($locationId: uuid!) {
  seasons(where: {locationId: {_eq: $locationId}}, order_by: [{startDate: desc}], limit: 1) {
    id
    name
    locationId
    startDate
    endDate
    created_at
    updated_at
  }
  closingDates(where: {locationId: {_eq: $locationId}}) {
    id
    locationId
    closedDate
    description
  }
}
    `;

/**
 * __useGetSeasonByLocationQuery__
 *
 * To run a query within a React component, call `useGetSeasonByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonByLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetSeasonByLocationQuery(baseOptions: Apollo.QueryHookOptions<GetSeasonByLocationQuery, GetSeasonByLocationQueryVariables>) {
        return Apollo.useQuery<GetSeasonByLocationQuery, GetSeasonByLocationQueryVariables>(GetSeasonByLocationDocument, baseOptions);
      }
export function useGetSeasonByLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeasonByLocationQuery, GetSeasonByLocationQueryVariables>) {
          return Apollo.useLazyQuery<GetSeasonByLocationQuery, GetSeasonByLocationQueryVariables>(GetSeasonByLocationDocument, baseOptions);
        }
export type GetSeasonByLocationQueryHookResult = ReturnType<typeof useGetSeasonByLocationQuery>;
export type GetSeasonByLocationLazyQueryHookResult = ReturnType<typeof useGetSeasonByLocationLazyQuery>;
export type GetSeasonByLocationQueryResult = Apollo.QueryResult<GetSeasonByLocationQuery, GetSeasonByLocationQueryVariables>;
export const BookingRequestDocument = gql`
    subscription BookingRequest($id: uuid!) {
  bookingRequest_by_pk(id: $id) {
    success
  }
}
    `;

/**
 * __useBookingRequestSubscription__
 *
 * To run a query within a React component, call `useBookingRequestSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBookingRequestSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingRequestSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingRequestSubscription(baseOptions: Apollo.SubscriptionHookOptions<BookingRequestSubscription, BookingRequestSubscriptionVariables>) {
        return Apollo.useSubscription<BookingRequestSubscription, BookingRequestSubscriptionVariables>(BookingRequestDocument, baseOptions);
      }
export type BookingRequestSubscriptionHookResult = ReturnType<typeof useBookingRequestSubscription>;
export type BookingRequestSubscriptionResult = Apollo.SubscriptionResult<BookingRequestSubscription>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": []
  }
};
      export default result;
    