import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { de } from 'date-fns/locale'

export const defaultFormat = (date: Date | string): string => {
  return "formatted date";
};

export const formatDateRange = (start: Date | string, end: Date | string): string => {
  let startString: string;

  if(!(start instanceof Date)) {
    start = parseISO(start);
  }

  if(!(end instanceof Date)) {
    end = parseISO(end);
  }

  if(start.getMonth() === end.getMonth()) {
    startString = format(start, 'd.', { locale: de });
  } else {
    startString = format(start, 'd. MMM', { locale: de });
  }

  const endString = format(end, 'd. MMM', { locale: de });

  let rangeString: string;

  if(start.toISOString() === end.toISOString()) {
    rangeString = `${endString}`;
  } else {
    rangeString = `${startString} - ${endString}`;
  }

  return rangeString;
};